var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('Breadcrumb',{attrs:{"paths":_vm.breadcrumbs}}),_c('div',{attrs:{"id":"clickwrap-terms-of-service"}}),_c('div',{staticClass:"contracts-header mb-4"},[_c('h1',{staticClass:"title is-1 has-text-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t('CHANGE_PLAN_ZCC.HEADER.TITLE'))+" ")]),_c('p',{staticClass:"has-text-grey-darker mb-0"},[_vm._v(" "+_vm._s(_vm.$t('CHANGE_PLAN_ZCC.HEADER.SUBTITLE'))+" ")]),_c('p',{staticClass:"has-text-grey-darker mb-0"},[_vm._v(" "+_vm._s(_vm.$t('CHANGE_PLAN_ZCC.HEADER.HELP_TEXT'))+" "),_c('a',{attrs:{"href":String(_vm.$t('CHANGE_PLAN_ZCC.HEADER.HELP_CENTER_LINK')),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('CHANGE_PLAN_ZCC.HEADER.HELP_CENTER'))+" ")])])]),_c('div',{staticClass:"select-plan-body is-flex flex-row align-center mb-3"},[_c('SelectPlanCard',{attrs:{"is-active":_vm.selectedType === 'software',"card-data":_vm.softwarePlanData,"default-selected":_vm.defaultSelectedPlan},on:{"onRadioButtonClicked":_vm.onCardCliked}}),_c('i',{staticClass:"fa-sharp fa-solid fa-plus has-text-grey-darker"}),_c('SelectPlanCard',{attrs:{"card-data":_vm.channelPlanData,"is-active":_vm.selectedType === 'channel',"default-selected":_vm.defaultSelectedChannel},on:{"onRadioButtonClicked":_vm.onCardCliked}}),_c('i',{staticClass:"fa-sharp fa-solid fa-equals has-text-grey-darker"}),_c('PlanSummaryCard',{attrs:{"is-button-loading":_vm.isChangePlanButtonLoading,"card-data":{
        currency: _vm.zccContract.currency,
        softwarePlan: _vm.softwarePlanSelected,
        channelPlan: _vm.channelPlanSelected,
      },"is-button-disabled":_vm.softwarePlanSelected === _vm.defaultSelectedPlan &&
          _vm.channelPlanSelected === _vm.defaultSelectedChannel},on:{"onSubmitPlanChange":_vm.renderClickWrapModal}})],1),_c('div',{staticClass:"select-plan-footer is-flex flex-column"},[_c('ChannelPlanDetails',{attrs:{"currency":_vm.zccContract.currency,"channel-plan-props":_vm.channelPlanSelected}}),(_vm.defaultSelectedPlan.id > 1)?_c('article',{staticClass:"hint is-info"},[_vm._m(0),_c('div',{staticClass:"hint-body"},[_c('h4',{staticClass:"title is-4"},[_vm._v(" "+_vm._s(_vm.$t('CHANGE_PLAN_ZCC.HINT_BOX.TITLE'))+" ")]),_c('span',{staticClass:"has-text-body"},[_vm._v(_vm._s(_vm.$t('CHANGE_PLAN_ZCC.HINT_BOX.TEXT')))]),_c('span',{staticClass:"button-hint"},[_c('a',{staticClass:"button",attrs:{"href":"#","data-track":"plan_cta-go_to_suport"},on:{"click":_vm.onRequestDowngradeToStarter}},[_vm._v(" "+_vm._s(_vm.$t('CHANGE_PLAN_ZCC.HINT_BOX.BUTTON_LABEL'))+" ")])])])]):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa-sharp fa-regular fa-circle-info"})])
}]

export { render, staticRenderFns }