/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable arrow-body-style */

import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

// @ts-expect-error:
import auth from '@platform/authentication';

import { logger, tracer } from 'logs-api-client';

import { ISession } from '@/types/session';

const getToken = () => {
  const { credential: { jwtData: { token } } } = auth.getSession() as ISession;
  return `Bearer ${token}`;
};

const handlerError = (error: any) => {
  let errorObj: any = error.message;
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);

    errorObj = error.response;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  console.log(error.config);
  logger.error('Request interseptor error!', { request: error.config, response: errorObj });
};

export default (axios: AxiosInstance) => {
  axios.interceptors.request.use(
    (value: AxiosRequestConfig) => {
      // Do something before request is sent
      const headers = value.headers || {};
      return {
        ...value,
        headers: {
          Authorization: getToken(),
          'x-traceId': tracer.getTraceId().traceId,
          ...headers,
        },
      };
    },
    (error) => {
      handlerError(error);
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    (value: AxiosResponse) => {
      // Do something with response data
      return value;
    },
    (error) => {
      handlerError(error);
      return Promise.reject(error);
    },
  );
};
