
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface IBreadcrumb {
  label?: string;
  to?: string;
  labelLink?: string;
  path?: string;
}

@Component
export default class Breadcrumb extends Vue {
  @Prop({
    required: true,
    default: () => [],
  }) paths!: IBreadcrumb[];

  get itens(): IBreadcrumb[] {
    return this.paths || [];
  }
}

