
import { Component, Vue } from 'vue-property-decorator';

import Container from '@/components/Container.vue';
import AsideMenu from '@/components/AsideMenu.vue';
import Toasts from '@/components/Toasts.vue';
import Loading from '@/components/Loading.vue';

@Component({
  components: {
    Container,
    AsideMenu,
    Toasts,
    Loading,
  },
})
export default class App extends Vue {
  public canShowLeftMenu = false;

  async created() {
    const canViewFeature = await this.$canIViewFeature({ name: 'customer-area' });
    const isAppContext = (window as any).maestroContextType === 'APP';
    this.canShowLeftMenu = isAppContext && canViewFeature;
  }
}

