import Plugin, { ICanViewFeatureOptions, canIViewFeature } from './feature-toggle';

declare module 'vue/types/vue' {
  interface Vue {
    $canIViewFeature: (options: ICanViewFeatureOptions) => Promise<boolean>;
  }
}

export default Plugin;

export { ICanViewFeatureOptions, canIViewFeature };
