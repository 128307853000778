import Vue from 'vue';
import Component from 'vue-class-component';

// @ts-expect-error:
import auth from '@platform/authentication';

import {
  ISession,
  ISessionCredential,
  ISessionOrganization,
  ISessionProfile,
  ISessionUser,
} from '@/types/session';

@Component
export default class SessionMixin extends Vue {
  get mxSession_getSession(): ISession {
    return auth.getSession();
  }

  get mxSession_credential(): ISessionCredential {
    return this.mxSession_getSession.credential;
  }

  get mxSession_organization(): ISessionOrganization {
    return this.mxSession_getSession.organization;
  }

  get mxSession_plan(): string {
    return this.mxSession_getSession.plan;
  }

  get mxSession_profile(): ISessionProfile {
    return this.mxSession_getSession.profile;
  }

  get mxSession_status(): string {
    return this.mxSession_getSession.status;
  }

  get mxSession_sysAdminViewMode(): boolean {
    return this.mxSession_getSession.sysAdminViewMode;
  }

  get mxSession_user(): ISessionUser {
    return this.mxSession_getSession.user;
  }
}

export const sessionMixin = new SessionMixin();
