
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

import { IFiscalDocument, DocumentType } from '@/types/fiscal-documents';

import ActionDocumentsItem from './ActionDocumentsItem.vue';
import ActionDocumentsPayableItem from './ActionDocumentsPayableItem.vue';

@Component({
  components: { ActionDocumentsItem, ActionDocumentsPayableItem },
})
export default class ActionDocuments extends Vue {
  @Prop({
    required: true,
    type: Array,
    default: () => [],
  }) readonly documents!: IFiscalDocument[];

  get invoiceDocument() {
    return this.getDocumentByType(DocumentType.invoice);
  }

  get invoiceSVADocument() {
    return this.getDocumentByType(DocumentType.invoiceSVA);
  }

  get billDocument() {
    return this.getDocumentByType(DocumentType.bill);
  }

  get xmlDocument() {
    return this.getDocumentByType(DocumentType.xml);
  }

  get detailedConsumptionDocument() {
    return this.getDocumentByType(DocumentType.detailedConsumption);
  }

  private checkDocumentType(type: string) {
    return type === 'BOLETO' ? 'bill' : type;
  }

  public showDetailedConsumption = false;

  async mounted() {
    this.showDetailedConsumption = await this.$canIViewFeature({ name: 'detailed-consumption-customer-area' });
  }

  getDocumentByType(type: DocumentType): IFiscalDocument {
    const emptyDocument = { url: '', type: DocumentType.bill };
    try {
      return this.documents.find((el: IFiscalDocument) => this.checkDocumentType(el.type) === type) || emptyDocument;
    } catch (e) {
      return emptyDocument;
    }
  }
}

