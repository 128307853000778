
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

import { required } from 'vuelidate/lib/validators';
import { Validations } from 'vuelidate-property-decorators';
import { Validation } from 'vuelidate';
import { ValidationEvaluation } from 'vue/types/vue';
import {
  alphabetCaracteres, alphaNumericCharactersWithSpecialsCharacters,
  validatePostalCodeBR, validatePostalCodeNotBR,
} from '@/shared/index';

import Modal from '@/components/Modal.vue';
import Input from '@/components/form/Input.vue';
import SelectCountry from '@/components/form/SelectCountry.vue';
import SelectStates from '@/components/form/SelectStates.vue';
import SelectCity from '@/components/form/SelectCity.vue';
import Checkbox from '@/components/form/Checkbox.vue';
import { IAddress } from '@/types/address';

@Component({
  components: {
    Modal,
    Input,
    SelectCountry,
    SelectStates,
    SelectCity,
    Checkbox,
  },
})
export default class ModalAddress extends Vue {
  @Prop({
    required: true,
    default: () => ({}),
    type: Object,
  }) readonly address!: IAddress;

  public data: IAddress = {
    address: '',
    addressLine2: '',
    city: '',
    country: '',
    district: '',
    postalCode: '',
    state: '',
  };

  public agreedTerm = false;

  public loading = false;

  @Validations()
  validations() {
    let validatePostalCode = validatePostalCodeNotBR;
    if (this.data.country === 'BR') {
      validatePostalCode = validatePostalCodeBR;
    }

    return {
      data: {
        address: { required, alphaNumericCharactersWithSpecialsCharacters },
        addressLine2: { alphaNumericCharactersWithSpecialsCharacters },
        city: { required, alphaNumericCharactersWithSpecialsCharacters },
        country: { required },
        district: { required, alphaNumericCharactersWithSpecialsCharacters },
        postalCode: { required, validatePostalCode },
        state: { required, alphabetCaracteres },
      },
    };
  }

  private get url() {
    return `${process.env.VUE_APP_CUSTOMER_PERSONAL_DATA_URL}/v1/address`;
  }

  public get canSaveAddress(): boolean {
    return !this.$v.data.$error && this.agreedTerm;
  }

  private get modalAddress(): Modal {
    return (this.$refs.modalAddress as Modal);
  }

  public get stateComponent(): string {
    switch (this.data.country) {
      case 'BR':
        return 'SelectStates';

      default:
        return 'Input';
    }
  }

  public messageError(field: Validation & ValidationEvaluation): string {
    field.$touch();

    const requiredError = !(field.required ?? true);
    if (requiredError) {
      return this.$t('MESSAGES.REQUIRED_FIELD').toString();
    }

    if (field.$error) {
      return this.$t('MESSAGES.INVALID_VALUE').toString();
    }

    return '';
  }

  public get cityComponent(): string {
    switch (this.data.country) {
      case 'BR':
        return 'SelectCity';

      default:
        return 'Input';
    }
  }

  private get selectedState(): string {
    return this.data.state;
  }

  public postalCodeBRMask() {
    if (this.data.country === 'BR') {
      const re = /^([\d]{5})-*([\d]{3})/;
      const formattedPostalCode = this.data.postalCode.replace(re, '$1-$2');
      this.data.postalCode = formattedPostalCode;
    }
  }

  @Watch('address', { deep: true })
  private changeData(newValue: IAddress) {
    this.copyAddressToInternalVariable(newValue);
  }

  mounted() {
    this.copyAddressToInternalVariable();
  }

  private copyAddressToInternalVariable(forceValue?: IAddress) {
    this.data = JSON.parse(JSON.stringify(forceValue || this.address));
  }

  public close() {
    this.copyAddressToInternalVariable();

    this.modalAddress.close();
  }

  public open() {
    this.modalAddress.open();
  }

  public toggle() {
    this.modalAddress.toggle();
  }

  public async saveAddress(): Promise<void> {
    this.$v.$touch();
    if (this.canSaveAddress) {
      this.loading = true;
      try {
        await this.$http.patch(this.url, this.data);

        this.$emit('addressUpdated');

        this.$alert.success({
          title: `${this.$t('ACCOUNT.ADDRESS.ACTIONS.EDIT_ADDRESS')}`,
          message: `${this.$t('ACCOUNT.ADDRESS.ADDRESS_UPDATED')}`,
        });

        this.close();
      } catch (error) {
        this.$alert.warning({
          title: `${this.$t('MESSAGES.ATTEMPTION')}`,
          message: `${this.$t('ACCOUNT.ADDRESS.ADDRESS_CANNOT_UPDATE')}`,
          duration: 5000,
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
