import Vue from 'vue';
import { PluginObject } from 'vue/types/umd';

import alert from './alert';

const Plugin: PluginObject<unknown> = {
  install: (intance: typeof Vue): void => {
    Object.defineProperties(intance.prototype, {
      $alert: {
        get() {
          return alert;
        },
      },
    });
  },
};

export default Plugin;
