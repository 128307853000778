import Plugin, { eventBus } from './event-bus';

import { IEventBus, Events } from './type';

declare module 'vue/types/vue' {
  interface Vue {
    $eventBus: IEventBus;
  }
}

export default Plugin;

export { Events, eventBus, IEventBus };
