
/* eslint-disable no-unused-expressions */

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { },
})
export default class Modal extends Vue {
  @Prop({
    required: true,
    type: String,
  }) readonly id!: string;

  @Prop({
    required: false,
    type: Boolean,
  }) readonly hasOpenButton!: boolean;

  @Prop({
    required: false,
    type: String,
  }) readonly label!: string;

  @Prop({
    required: false,
    type: String,
  }) readonly title!: string;

  @Prop({
    required: false,
    type: Boolean,
  }) readonly hiddeClose!: boolean;

  @Prop({
    required: false,
    type: String,
    default: 'small',
    validator: (value: string) => ['small', 'mid', 'large'].includes(value),
  }) readonly type!: string;

  private get modalElement(): HTMLElement {
    return (this.$refs[this.id] as HTMLElement);
  }

  public open() {
    this.modalElement.classList.add('is-active');
  }

  public close() {
    this.modalElement.classList.remove('is-active');
  }

  public toggle() {
    this.modalElement.classList.toggle('is-active');
  }
}
