
import { Component, Prop, Vue } from 'vue-property-decorator';
// @ts-expect-error:
import FeedbackService from '@platform/feedback-service';
import { IContract } from '@/types/contract';

@Component
export default class Contracts extends Vue {
  @Prop({
    required: true,
    default: () => ({}),
    type: Object,
  }) readonly contract!: IContract;

  @Prop({
    required: false,
    default: null,
    type: Function,
  }) readonly onSelected!: (newContract: IContract) => void;

  public showChangePlanOption = false;

  async created() {
    const canViewFeature = await this.$canIViewFeature({ name: 'change-plan' });
    const isAppContext = (window as any).maestroContextType === 'APP';
    this.showChangePlanOption = isAppContext && canViewFeature;
  }

  public get contractLink(): string {
    let routeUrl = '/payments/contracts';
    if ((window as any).maestroContextType === 'SUITE') routeUrl = '/plans-and-contracts';
    return `${routeUrl}/${this.contractKey}/charging`;
  }

  public get changePlanLink(): string {
    let routeUrl = '/payments/contracts';
    if ((window as any).maestroContextType === 'SUITE') routeUrl = '/plans-and-contracts';
    return `${routeUrl}/${this.contractKey}/change`;
  }

  public get contractKey(): string {
    return this.contract?.contractID ?? this.contract?.salesforceContractID;
  }

  public get contractTag() {
    return this.$route.path === this.contractLink ? 'div' : 'a';
  }

  public contractSelected() {
    if (this.onSelected && typeof this.onSelected === 'function') {
      this.onSelected(this.contract);
    }
  }

  async handleChangePlanRequest() {
    const uri = `${process.env.VUE_APP_CUSTOMER_ORDER_MANAGER}/v1/plans/request-change`;
    let status = 'success';
    let message = 'SUCCESS';

    try {
      await this.$http.post(uri, { contractNumber: this.contract.salesforceContractID });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      message = 'ERROR';
      status = 'danger';
    }
    FeedbackService.toastr.show(status, this.$t(`INVOICE.CHANGE_PLAN_FEEDBACK.${message}`));
  }
}

