import alert, { Alert, IAlertOptions } from './alert';

import Plugin from './plugin';

declare module 'vue/types/vue' {
  interface Vue {
    $alert: Alert;
  }
}

export default Plugin;

export { alert, IAlertOptions };
