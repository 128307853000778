import { render, staticRenderFns } from "./ModalActivate2FA.vue?vue&type=template&id=d9344712&scoped=true"
import script from "./ModalActivate2FA.vue?vue&type=script&lang=ts"
export * from "./ModalActivate2FA.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9344712",
  null
  
)

export default component.exports