
import { Component } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import { mixins } from 'vue-class-component';
import SessionMixin from '@/mixins/session';

@Component
export default class AsideMenu extends mixins(SessionMixin) {
  private menuItems: RouteConfig[] = [];

  private canIViewNewCustomerArea = false;

  public async itens(): Promise<Array<RouteConfig>> {
    const items = (this.$router.options.routes || [])
      .filter((e: RouteConfig): boolean => !e.meta?.hidden)
      .filter((e: RouteConfig): boolean => !e.meta?.profiles || e.meta?.profiles.find((el: string) => el === this.mxSession_profile.id));

    const filterChildren: RouteConfig[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      filterChildren.push(...this.filterChildren(item.children || []));
    }

    return this.checkMenuItemFeature(filterChildren);
  }

  private async checkMenuItemFeature(childrens: RouteConfig[]): Promise<Array<RouteConfig>> {
    const filterChildren: RouteConfig[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for await (const child of childrens) {
      const feature = child.meta?.featureName;

      if (feature) {
        const canIViewFeature = await this.$canIViewFeature({ name: feature });
        if (canIViewFeature) {
          filterChildren.push(child);

          if (feature === 'new-customer-area') {
            this.canIViewNewCustomerArea = true;
          }
        }
      } else {
        filterChildren.push(child);
      }
    }

    return filterChildren;
  }

  public filterChildren(children: Array<RouteConfig>) {
    const filteredChildren = children
      .filter((e: RouteConfig): boolean => !e.meta?.hidden)
      .filter((e: RouteConfig): boolean => !e.meta?.profiles || e.meta?.profiles.find((el: string) => el === this.mxSession_profile.id));

    return filteredChildren;
  }

  private checkIconClass(icon: string): string {
    if (icon.split('|').length === 1) {
      return icon;
    }

    if (this.canIViewNewCustomerArea) {
      return icon.split('|')[1];
    }

    return icon.split('|')[0];
  }

  async created() {
    const items = await this.itens();

    const myAccountIndex = items.findIndex((item) => item.name === 'MY_ACCOUNT');

    if (myAccountIndex !== -1) {
      const myAccountItem = items.splice(myAccountIndex, 1)[0];
      items.unshift(myAccountItem);
    }

    this.menuItems = items;
  }
}

