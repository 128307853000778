/* eslint-disable @typescript-eslint/no-explicit-any */

export enum Events {
  Alert = 'g-alert',
  LoadingShow = 'g-loading-show',
  LoadingHide = 'g-loading-hide',
}

export interface IEventBus {
  $emit: (event: string, ...args: any[]) => void;
  $off: (event: string, ...args: any[]) => void;
  $on: (event: string, ...args: any[]) => void;
  $once: (event: string, ...args: any[]) => void;
}
