import { StatusType, StatusLabel } from '@/types/fiscal-documents';

export default class Payload {
  public vueInstance;

  public statusType;

  public statusLabel;

  // eslint-disable-next-line
  constructor(vueInstance: any) {
    this.vueInstance = vueInstance;
    this.statusType = StatusType;
    this.statusLabel = StatusLabel;
  }

  public referenceDate(): string {
    try {
      const date = this.vueInstance.paymentHistory.referenceDate;

      // @ts-ignore
      return this.vueInstance.$d(new Date(date.split('-')), 'monthAndYear');
    } catch (e) {
      console.log('error', e);
      return ' - ';
    }
  }

  public dueDate() {
    try {
      const date = this.vueInstance.paymentHistory.dueDate;
      // @ts-ignore
      return this.vueInstance.$d(new Date(date.split('-')));
    } catch (e) {
      return ' - ';
    }
  }

  public situationType() {
    try {
      return this.statusType[this.vueInstance.paymentHistory.situation as keyof Object] || 'info';
    } catch (e) {
      return ' - ';
    }
  }

  public situation() {
    try {
      const label = this.statusLabel[this.vueInstance.paymentHistory.situation as keyof Object];
      if (label) {
        return this.vueInstance.$t(`FISCAL_DOCUMENTS.PAYMENT_HISTORY.STATUS.${label}`).toString();
      }
      return ' - ';
    } catch (e) {
      return ' - ';
    }
  }

  public value() {
    try {
      return this.vueInstance.$n(this.vueInstance.paymentHistory.value, 'currency');
    } catch (e) {
      return ' - ';
    }
  }
}
