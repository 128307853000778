
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Badges extends Vue {
  @Prop({
    required: true,
    type: String,
  }) readonly label!: string;

  @Prop({
    required: true,
    type: String,
    validator: (value: string) => {
      const options = new Set(['light', 'info', 'success', 'warning', 'danger']);
      return options.has(value);
    },
  }) readonly type!: string;

  private icons = {
    success: 'fa-check-circle',
    warning: 'fa-exclamation-triangle',
    danger: 'fa-times-circle',
  }
}
