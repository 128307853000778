
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import ContractStore from '@/store/modules/contract';
import PageHeader from '@/components/PageHeader.vue';
import Breadcrumb, { IBreadcrumb } from '@/components/Breadcrumb.vue';
import AsyncProvider from '@/components/AsyncProvider.vue';
import Hint from '@/components/Hint.vue';

import TablePaymentHistory from './components/TablePaymentHistory.vue';
import CardContract from '../invoices/components/CardContract.vue';

const contract = namespace('contract');

@Component({
  components: {
    PageHeader,
    Breadcrumb,
    CardContract,
    TablePaymentHistory,
    AsyncProvider,
    Hint,
  },
})
export default class FiscalDocumentsView extends Vue {
  public contractsUrl = '/payments/contracts';

  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        labelLink: `${this.$t('INVOICES_RECEIPTS.BREADCRUMBS.HOME')}`,
        path: '/welcome',
      },
      {
        label: `${this.$t('INVOICES_RECEIPTS.BREADCRUMBS.CUSTOMER_AREA')}`,
        to: '/preferences/my-account',
      },
      {
        label: this.$tc('MENU_LEFT.INVOICES', 1).toString(),
        to: '/payments/contracts',
      },
      {
        label: this.$t('MENU_LEFT.FISCAL_DOCUMENTS').toString(),
        to: '#',
      },
    ];
  }

  private get contractId() {
    return this.$route.params.contract_id;
  }

  public get url(): string {
    return `${process.env.VUE_APP_PAYMENT_HISTORY_URL}/v2/contract/${this.contract.salesforceContractID}/payment-history`;
  }

  @contract.Getter
  public contract!: ContractStore;

  @contract.Mutation
  public clearContract!: () => void

  private mounted() {
    this.tryReachStoredContract();
  }

  private destroyed() {
    this.clearContract();
  }

  private tryReachStoredContract() {
    setTimeout(() => {
      if ((this.contract?.contractID || this.contract?.salesforceContractID) !== this.contractId) {
        this.$router.push(this.contractsUrl);
      } else {
        this.requestFiscalDocuments();
      }
    }, 100);
  }

  private requestFiscalDocuments(): void {
    (this.$refs.fiscalDocumentsProvider as AsyncProvider).requestData();
  }
}

