import Vue from 'vue';
import VueRouter from 'vue-router';

import { beforeEach } from './guards/profileGuard';

import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: (window as any).maestroContextType === 'SUITE' ? process.env.VUE_APP_SUITE_BASE_URL : process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
