import { helpers } from 'vuelidate/lib/validators';

const alphabetCaracteres = helpers.regex('alphaNum', /^[A-zÀ-ú ]*$/);
const alphaNumericCaracteres = helpers.regex('alphaNum', /^[A-zÀ-ú0-9_ ]*$/);
const alphaNumericCharactersWithSpecialsCharacters = helpers.regex('alphaNum', /^[_A-zÀ-ú0-9]*((-|`|'|\.|,|\s)*[_A-zÀ-ú0-9])*$/);
const validatePostalCodeBR = helpers.regex('alphaNum', /[0-9]{5}-[0-9]{3,4}$/);
const validatePostalCodeNotBR = helpers.regex('alphaNum', /^[_A-z0-9]*((-|\.|\s)*[_A-z0-9])*$/);

export {
  alphabetCaracteres,
  alphaNumericCaracteres,
  alphaNumericCharactersWithSpecialsCharacters,
  validatePostalCodeBR,
  validatePostalCodeNotBR,
};
