
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Skeleton extends Vue {
  @Prop({
    required: false,
    default: 1,
    type: Number,
  }) readonly quantity!: number;

  @Prop({
    required: false,
    default: 1,
    type: Number,
  }) readonly margin!: number;

  @Prop({
    required: false,
    default: 1,
    type: Number,
  }) readonly height!: number;

  get itens() {
    return new Array(this.quantity || 1);
  }
}

