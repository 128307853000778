/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/prefer-default-export
const CLICK_WRAP_IDS_HLG = {
  BRA: '25f17fcd-c0fd-4efa-a1e1-c5b7d6a6232f',
  MEX: '1caaf581-a652-4742-8e14-fb2a26a4049b',
  USA: 'b8361a1c-a0e8-4eed-8054-812e118dfa33',
  ARG: '0a8821f0-e8c7-4265-b005-526ded58f82d',
};

const CLICK_WRAP_IDS_PRD = {
  BRA: '5dcc6bdb-3302-4ef8-b4fa-6558485be5e0',
  MEX: '682252e6-e706-4260-a06b-4a93202fbe4c',
  USA: 'a0db6095-9195-420d-b2b5-d90ab5a3ec66',
  ARG: '4f9f0eb3-a6ec-4a67-8575-7675956d4ad2',
};

export const getClickWrapId = (country: string): string => {
  if (process.env.NODE_ENV === 'homologation') {
    if (Object.keys(CLICK_WRAP_IDS_HLG).includes(country)) {
      return CLICK_WRAP_IDS_HLG[country as keyof typeof CLICK_WRAP_IDS_HLG];
    }
    return CLICK_WRAP_IDS_HLG.USA;
  }

  if (Object.keys(CLICK_WRAP_IDS_PRD).includes(country)) {
    return CLICK_WRAP_IDS_PRD[country as keyof typeof CLICK_WRAP_IDS_PRD];
  }

  return CLICK_WRAP_IDS_PRD.USA;
};
