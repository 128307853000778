var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"box-card is-fullwidth"},[_c('div',{staticClass:"title is-4"},[_vm._v(" "+_vm._s(_vm.$t('CHANGE_PLAN_ZCC.PLAN_SUMMARY_CARD.TITLE'))+" ")]),_c('div',{staticClass:"summary-card-body is-flex flex-column"},[_c('div',{staticClass:"plan"},[_c('div',{staticClass:"plan-value is-flex flex-row justify-between align-center p-2"},[_c('span',{staticClass:"plan-title has-text-caption is-software"},[_vm._v(" "+_vm._s(_vm.$t('CHANGE_PLAN_ZCC.PLAN_SUMMARY_CARD.SOFTWARE_PLAN_TITLE'))+" ")]),_c('div',{staticClass:"plan-value is-flex flex-row align-center"},[_c('span',{staticClass:"plan-value has-text-weight-bold is-flex align-center"},[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.cardData.currency, _vm.cardData.softwarePlan.price))+" ")]),_c('span',{staticClass:"has-text-small"},[_vm._v(" / "+_vm._s(_vm.$t('CHANGE_PLAN_ZCC.PLAN_SUMMARY_CARD.MONTHS_LABEL'))+" ")])])]),_c('div',{staticClass:"plan-description p-2"},[_c('PlanCapabilities',{attrs:{"capabilities-props":{
            currency: _vm.cardData.currency,
            size: 'caption',
            softwarePlan: {
              users: _vm.cardData.softwarePlan.users,
              interactionz: _vm.cardData.softwarePlan.interactionz,
              priceForExceededUsers: _vm.cardData.softwarePlan.priceForExceededUsers,
              priceForExceededInteratcionz: _vm.cardData.softwarePlan.priceForExceededInteratcionz,
            }
          }}})],1)]),_c('div',{staticClass:"plan"},[_c('div',{staticClass:"plan-value is-flex flex-row justify-between align-center p-2"},[_c('span',{staticClass:"plan-title has-text-caption is-channel"},[_vm._v(" "+_vm._s(_vm.$t('CHANGE_PLAN_ZCC.PLAN_SUMMARY_CARD.CHANNEL_PLAN_TITLE'))+" ")]),_c('div',{staticClass:"plan-value is-flex flex-row align-center"},[_c('span',{staticClass:"plan-value has-text-weight-bold is-flex align-center"},[_vm._v(_vm._s(_vm.formatCurrency(_vm.cardData.currency, _vm.cardData.channelPlan.price)))]),_c('span',{staticClass:"has-text-small"},[_vm._v(" / "+_vm._s(_vm.$t('CHANGE_PLAN_ZCC.PLAN_SUMMARY_CARD.MONTHS_LABEL'))+" ")])])]),_c('div',{staticClass:"plan-description p-2"},[_c('PlanCapabilities',{attrs:{"capabilities-props":{
            currency: _vm.cardData.currency,
            size: 'caption',
            channelPlan: {
              sms: _vm.cardData.channelPlan.sms,
              whatsApp: _vm.cardData.channelPlan.whatsapp,
              email: _vm.cardData.channelPlan.email,
            }
          }}})],1)])]),_c('div',{staticClass:"summary-card-footer"},[_c('div',{staticClass:"sumary-footer-value is-flex flex-row justify-between align-center p-2"},[_c('span',{staticClass:"has-text-caption"},[_vm._v(" "+_vm._s(_vm.$t('CHANGE_PLAN_ZCC.PLAN_SUMMARY_CARD.TOTAL_VALUE_LABEL'))+" ")]),_c('div',{staticClass:"plan-total is-flex flex-row align-center"},[_c('span',{staticClass:"plan-total-value has-text-weight-bold is-flex align-center"},[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.cardData.currency, _vm.cardData.softwarePlan.price + _vm.cardData.channelPlan.price))+" ")]),_c('span',{staticClass:"has-text-small"},[_vm._v(" / "+_vm._s(_vm.$t('CHANGE_PLAN_ZCC.PLAN_SUMMARY_CARD.MONTHS_LABEL'))+" ")])])]),_c('button',{staticClass:"button is-primary is-fullwidth",class:{'is-loading': _vm.isButtonLoading },attrs:{"disabled":_vm.isButtonDisabled,"data-track":"plan_cta-go_to_change"},on:{"click":_vm.handleSubmitPlanChange}},[_vm._v(" "+_vm._s(_vm.$t('CHANGE_PLAN_ZCC.PLAN_SUMMARY_CARD.CHANGE_PLAN_BUTTON'))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }