export const numberFormats = {
  en: {
    currency: {
      style: 'currency', currency: 'BRL',
    },
  },

  es: {
    currency: {
      style: 'currency', currency: 'BRL',
    },
  },

  pt: {
    currency: {
      style: 'currency', currency: 'BRL',
    },
  },
};

export const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric', month: 'short', day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    monthAndYear: {
      year: 'numeric', month: 'long',
    },
    month: {
      month: 'long',
    },
  },

  es: {
    short: {
      year: 'numeric', month: 'short', day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    monthAndYear: {
      year: 'numeric', month: 'long',
    },
    month: {
      month: 'long',
    },
  },

  pt: {
    short: {
      year: 'numeric', month: 'short', day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    monthAndYear: {
      year: 'numeric', month: 'long',
    },
    month: {
      month: 'long',
    },
  },
};
