
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class IconBase extends Vue {
  @Prop({
    required: false,
    type: String,
  }) readonly styleIcon!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly iconClass!: string;
}
