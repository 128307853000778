
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

import { IPaymentHistory, IPaymentVueInstance } from '@/types/payment-history';
import Badges from '@/components/Badges.vue';

import Payload from '@/services/Payload';
import ActionDocuments from './ActionDocuments.vue';

@Component({
  components: { Badges, ActionDocuments },
})
export default class RowPaymentHistory extends Vue {
  @Prop({
    required: true,
    default: () => ({}),
    type: Object,
  }) readonly paymentHistory!: IPaymentHistory;

  // @ts-ignore
  ErpClass: IPaymentVueInstance;

  created() {
    // @ts-ignore
    this.ErpClass = new Payload(this);
  }

  get referenceDate(): string {
    return this.ErpClass.referenceDate();
  }

  get value(): string {
    return this.ErpClass.value();
  }

  get dueDate(): string {
    return this.ErpClass.dueDate();
  }

  get situation(): string {
    return this.ErpClass.situation();
  }

  get situationType(): string {
    return this.ErpClass.situationType();
  }
}
