
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class AddressLine extends Vue {
  @Prop({
    required: true,
    type: String,
  }) readonly id!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly label!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly data!: string;
}
