var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"box-card is-fullwidth",class:[
    { 'is-selected': _vm.isActive },
    { 'pink': _vm.cardData.type === 'software' },
    { 'purple': _vm.cardData.type === 'channel' }
  ]},[_c('div',{staticClass:"title is-4",class:`${_vm.cardData.type === 'software' ? 'pink' : 'purple'}`},[_vm._v(" "+_vm._s(_vm.cardData.title)+" ")]),_c('div',{staticClass:"select-plan-card-body"},[_c('div',{staticClass:"field is-flex flex-column"},_vm._l((_vm.cardData.data),function(item,index){return _c('div',{key:index,staticClass:"plan-options is-flex flex-column",class:[
          {'is-active': _vm.selectedItem.id === item.id},
          {'is-software': _vm.cardData.type === 'software'},
          {'tooltip': item.id === 1 && _vm.defaultSelected.id > 1 && _vm.cardData.type === 'software'}
        ],attrs:{"data-tooltip":_vm.$t('CHANGE_PLAN_ZCC.SELECT_PLAN_CARD.STARTER_PLAN_TOOLTIP')}},[(_vm.selectedItem.id === item.id && item.id === _vm.defaultSelected.id)?_c('span',{staticClass:"has-text-small"},[_vm._v(" "+_vm._s(_vm.cardData.type === 'software' ? _vm.$t('CHANGE_PLAN_ZCC.SELECT_PLAN_CARD.CURRENT_SOFTWARE_PLAN_LABEL') : _vm.$t('CHANGE_PLAN_ZCC.SELECT_PLAN_CARD.CURRENT_CHANNEL_PLAN_LABEL'))+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedItem),expression:"selectedItem"}],staticClass:"checkradio",attrs:{"id":`${_vm.cardData.type === 'channel' ? 'pack' : ''}${item.id}-${index}`,"type":"radio","name":`${_vm.cardData.type}-option`,"disabled":item.id === 1 && _vm.defaultSelected.id > 1 && _vm.cardData.type === 'software',"data-track":`plan-${_vm.cardData.type === 'channel' ? 'canal' : 'plano'}_radio-button_change`},domProps:{"value":item,"checked":_vm._q(_vm.selectedItem,item)},on:{"change":[function($event){_vm.selectedItem=item},function($event){return _vm.valueChanged(item)}]}}),_c('label',{staticClass:"plan-label is-flex flex-row justify-between",attrs:{"for":`${_vm.cardData.type === 'channel' ? 'pack' : ''}${item.id}-${index}`}},[_c('span',[_vm._v(_vm._s(_vm.cardData.type === 'software' ? item.name : _vm.$t('CHANGE_PLAN_ZCC.SELECT_PLAN_CARD.CHANNEL_PLAN_LABEL')))]),_c('span',[_c('span',{staticClass:"plan-value has-text-weight-bold"},[_vm._v(_vm._s(_vm.formatCurrency(_vm.cardData.currency, item.price)))]),_c('span',{staticClass:"has-text-small"},[_vm._v(" / "+_vm._s(_vm.$t('CHANGE_PLAN_ZCC.SELECT_PLAN_CARD.MONTHS_LABEL')))])])]),(_vm.selectedItem.id === item.id && _vm.cardData.type === 'software')?_c('div',{staticClass:"pl-1"},[_c('PlanCapabilities',{attrs:{"capabilities-props":{
              currency: _vm.cardData.currency,
              size: 'small',
              softwarePlan: {
                users: item.users,
                interactionz: item.interactionz,
                priceForExceededUsers: item.priceForExceededUsers,
                priceForExceededInteratcionz: item.priceForExceededInteratcionz,
              }
            }}})],1):_vm._e(),(_vm.selectedItem.id === item.id && _vm.cardData.type === 'channel')?_c('div',{staticClass:"pl-1"},[_c('PlanCapabilities',{attrs:{"capabilities-props":{
              currency: _vm.cardData.currency,
              size: 'small',
              channelPlan: {
                sms: item.sms,
                whatsApp: item.whatsapp,
                email: item.email,
              }
            }}})],1):_vm._e()])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }