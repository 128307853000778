import { AxiosStatic } from 'axios';

import Plugin, { axios } from './axios';

declare module 'vue/types/vue' {
  interface Vue {
    $http: AxiosStatic;
  }
}

export default Plugin;

export { axios };
