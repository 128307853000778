import { render, staticRenderFns } from "./CardContract.vue?vue&type=template&id=1ae414a1&scoped=true"
import script from "./CardContract.vue?vue&type=script&lang=ts"
export * from "./CardContract.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ae414a1",
  null
  
)

export default component.exports