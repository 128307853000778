import Vue from 'vue';
import Component from 'vue-class-component';
import { AxiosError } from 'axios';

@Component
export default class DownloadDocumentMixin extends Vue {
  private urlsCache = new Map<string, string>();

  public async mxDownloadDoc_openStream(url: string, type: string): Promise<void> {
    this.mxDownloadDoc_changeLoading(true);
    try {
      const fileURL = await this.mxDownloadDoc_getFileUrl(url, type);

      const fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.target = '_blank';

      fileLink.click();
    } catch (error) {
      // @ts-ignore
      this.mxDownloadDoc_handlingError(error);
    } finally {
      this.mxDownloadDoc_changeLoading(false);
    }
  }

  private mxDownloadDoc_changeLoading(loading: boolean) {
    if (loading) {
      const message = `${this.$t('MESSAGES.LOADING.DOWNLOAD_DOCUMENT')}`;
      this.$loading.show(message);
    } else {
      this.$loading.hide();
    }
  }

  private mxDownloadDoc_handlingError(error: AxiosError) {
    if (error.code === 'ECONNABORTED') {
      this.$alert.warning({
        title: `${this.$t('MESSAGES.DOCUMENT_TIMEOUT.TITLE')}`,
        message: `${this.$t('MESSAGES.DOCUMENT_TIMEOUT.MESSAGE')}`,
        duration: 7000,
      });
      return;
    }
    this.$alert.danger({
      title: `${this.$t('MESSAGES.ATTEMPTION')}`,
      message: `${this.$t('MESSAGES.ERROR_TRY_AGAIN')}`,
      duration: 5000,
    });
  }

  private async mxDownloadDoc_getFileUrl(url: string, type: string): Promise<string> {
    let fileURL = this.urlsCache.get(url);

    if (!fileURL) {
      const { data } = await this.$http.get(url, { responseType: 'blob', timeout: 30 * 1000 });

      fileURL = window.URL.createObjectURL(new Blob([data], { type: `application/${type}` }));

      this.urlsCache.set(url, fileURL);
    }
    return fileURL;
  }
}

export const downloadDocumentMixin = new DownloadDocumentMixin();
