import { RouteConfig } from 'vue-router';

import DefaultLayout from '@/layouts/Default.vue';
import AccountView from '@/views/account/Account.vue';

const routes: Array<RouteConfig> = [
  {
    name: 'PREFERENCES',
    path: '/preferences',
    redirect: '/preferences/my-account',
    component: DefaultLayout,
    beforeEnter: (to, from, next) => {
      if ((window as any).maestroContextType === 'APP') {
        next();
      } else if ((window as any).maestroContextType === 'SUITE') next('/users');
    },
    children: [
      {
        name: 'MY_ACCOUNT',
        path: '/preferences/my-account',
        component: AccountView,
        meta: {
          iconClass: 'fa-regular fa-user',
          hidden: (window as any).maestroContextType === 'SUITE',
        },
      },
    ],
  },
  {
    name: 'PREFERENCES SUITE',
    path: '/users',
    redirect: '/users/my-account',
    component: DefaultLayout,
    beforeEnter: (to, from, next) => {
      if ((window as any).maestroContextType === 'SUITE') {
        next();
      } next('/preferences');
    },
    children: [
      {
        name: 'MY_ACCOUNT',
        path: '/users/my-account',
        component: AccountView,
        meta: {
          iconClass: 'fa-regular fa-user',
          hidden: (window as any).maestroContextType === 'APP',
        },
      },
    ],
  },
];

export default routes;
