
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { },
})
export default class Buttons extends Vue {
  @Prop({
    required: false,
    type: String,
  }) readonly classButton!: string;

  @Prop({
    required: false,
    type: String,
  }) readonly labelButton!: string;

   @Prop({
     required: false,
     type: String,
   }) readonly iconButton!: string;

   @Prop({
     required: false,
     type: String,
   }) readonly classLabel!: string;

   @Prop({
     required: false,
     type: String,
   }) readonly classIconButton!: string;

   @Prop({
     required: false,
     type: Boolean,
   }) readonly isDisabledButton!: boolean;

   @Prop({
     required: false,
     type: Function,
   }) readonly handerClick!: Function;

   @Prop({
     required: false,
     type: String,
   }) readonly dataTrackButton!: string;

    @Prop({
      required: false,
      type: String,
    }) readonly idButton!: string;
}
