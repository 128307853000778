
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

import { IPaymentHistory } from '@/types/payment-history';
import SkeletonTable from '@/components/SkeletonTable.vue';

import RowPaymentHistory from './RowPaymentHistory.vue';

@Component({
  components: {
    SkeletonTable,
    RowPaymentHistory,
  },
})
export default class TablePaymentHistory extends Vue {
  @Prop({
    required: true,
    default: () => [],
    type: Array,
  }) readonly paymentHistory!: IPaymentHistory[];

  @Prop({
    required: false,
    default: false,
    type: Boolean,
  }) readonly loading!: boolean;

  public get orderedPaymentHistory() {
    return this.paymentHistory
      .sort((a: IPaymentHistory, b: IPaymentHistory): number => {
        try {
          const dateA = new Date(a.referenceDate).getTime();
          const dateB = new Date(b.referenceDate).getTime();
          return dateB - dateA;
        } catch (error) {
          return 0;
        }
      });
  }
}

