
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CardSoftwarePlanSummary extends Vue {
  // eslint-disable-next-line
  constructor(vueInstance: any) {
    super();
  }
}

