
import { Component, Prop, Vue } from 'vue-property-decorator';
// @ts-expect-error:
import autentication from '@platform/authentication';
// @ts-expect-error:
import customerDataService from '@platform/customer-data-service';
import { logger } from 'logs-api-client';

export interface IPasswordHistoryError {
  code: string;
  message: string;
  httpStatusCode: number;
}

@Component
export default class TabChangePassword extends Vue {
  @Prop({
    required: true,
    default: 'my-account',
    type: String,
  }) readonly activeTab!: string;

  public loading = false;

  public passwordStrength = '';

  public hasPasswordVisible = false;

  public invalidPassword = false;

  public newPassword = '';

  public passwordHistory = process.env.VUE_APP_PASSWORD_HISTORY;

  public isBlocked = false;

  async created() {
    await this.fetchPlanStatus();
  }

  private async fetchPlanStatus() {
    try {
      const context = await customerDataService.getContext();
      this.isBlocked = context.planStatus === 'BLOCKED';
    } catch (error: any) {
      logger.error(`${error}`, error);
    }
  }

  public onChangePassword() {
    this.invalidPassword = false;
  }

  public validatePassword(newPassword:string) {
    if (newPassword) {
      const letter = /[A-Za-z]/;
      const numberTest = /[0-9]/;
      const specialCharacter = /[!@#$%^&*)(+=._-]/;

      const hasLetter = letter.test(newPassword);
      const hasNumberCase = numberTest.test(newPassword);
      const hasSpecialCharacter = specialCharacter.test(newPassword);

      if (hasLetter && hasNumberCase && hasSpecialCharacter && newPassword.length >= 8) {
        this.passwordStrength = 'STRONG';
      } else if (
        (
          (hasNumberCase || hasSpecialCharacter) && (hasLetter && newPassword.length >= 8)
        ) || (hasLetter && this.newPassword.length >= 14)
      ) {
        this.passwordStrength = 'GOOD';
      } else {
        this.passwordStrength = 'WEAK';
      }

      this.invalidPassword = !(this.newPassword && this.checkRequirementPassword(this.newPassword));
    }
  }

  public togglePassword() {
    this.hasPasswordVisible = !this.hasPasswordVisible;
  }

  public async changePassword() {
    this.validatePassword(this.newPassword);

    if (!this.loading) {
      if (this.newPassword && this.checkRequirementPassword(this.newPassword)) {
        this.loading = true;
        try {
          await autentication.changePassword(this.newPassword);
          this.$alert.success({
            message: `${this.$t('ACCOUNT.PASSWORD_UPDATED_SUCCESS')}`,
          });
          this.newPassword = '';
        } catch (error: any) {
          const { response: { data } } = error;

          const errorMessageCode = this.checkPasswordHistory(data);
          this.$alert.danger({
            message: `${this.$t(`ACCOUNT.${errorMessageCode}`, { password_history: this.passwordHistory })}`,
          });
        } finally {
          this.loading = false;
        }
      } else {
        this.invalidPassword = true;
      }
    }
  }

  public checkPasswordHistory(data: IPasswordHistoryError): string {
    const { code, httpStatusCode } = data;

    if (httpStatusCode === 400 && code === 'PasswordHistoryError') {
      return 'PASSWORD_HISTORY_ERROR';
    }
    return 'PASSWORD_UPDATED_FAIL';
  }

  private checkRequirementPassword(password: string) {
    const rule = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*()/\-+=_^?])(?=.{8,})/;
    return rule.test(password);
  }

  public hasHistory() {
    return window.history.length > 2;
  }
}
