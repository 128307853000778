import { RouteConfig } from 'vue-router';

import DefaultLayout from '@/layouts/Default.vue';
import InvoicesView from '@/views/invoices/Invoices.vue';
import InvoicesZcc from '@/views/invoices/InvoicesZcc.vue';
import FiscalDocumentsView from '@/views/fiscal-documents/FiscalDocuments.vue';
import InvoicesReceiptsView from '@/views/invoicesReceipts/InvoicesReceipts.vue';
import Profile from '@/types/profiles';
import ChangePlanZCCView from '@/views/change-plan-zcc/ChangePlanZCC.vue';

const routes: Array<RouteConfig> = [
  {
    name: 'PAYMENTS',
    path: '/payments',
    redirect: '/payments/contracts',
    component: DefaultLayout,
    meta: {
      profiles: [Profile.SYS_ADMIN, Profile.ADMIN],
    },
    beforeEnter: (to, from, next) => {
      if ((window as any).maestroContextType === 'APP') {
        next();
      } else next('/customer-area');
    },
    children: [
      {
        name: 'CONTRACTS',
        path: '/payments/contracts',
        component: InvoicesView,
        meta: {
          profiles: [Profile.SYS_ADMIN, Profile.ADMIN],
          iconClass: 'fa-light fa-file-invoice-dollar | fa fa-list-check',
          hidden: (window as any).maestroContextType === 'SUITE',
        },
      },
      {
        name: 'FISCAL_DOCUMENTS',
        path: '/payments/contracts/:contract_id/charging',
        component: FiscalDocumentsView,
        meta: {
          hidden: true,
          profiles: [Profile.SYS_ADMIN, Profile.ADMIN],
        },
      },
      {
        name: 'INVOICES_RECEIPTS',
        path: '/payments/financial/',
        component: InvoicesReceiptsView,
        meta: {
          profiles: [Profile.SYS_ADMIN, Profile.ADMIN],
          iconClass: 'fa-light fa-file-invoice-dollar',
          featureName: 'new-customer-area',
          hidden: (window as any).maestroContextType === 'SUITE',
        },
      },
    ],
  },
  {
    name: 'PAYMENTS SUITE',
    path: '/customer-area',
    redirect: '/plans-and-contracts',
    component: DefaultLayout,
    meta: {
      profiles: [Profile.SYS_ADMIN, Profile.ADMIN],
    },
    beforeEnter: (to, from, next) => {
      if ((window as any).maestroContextType === 'SUITE') {
        next();
      } else next('/payments');
    },
    children: [
      {
        name: 'CONTRACTS_SUITE',
        path: '/plans-and-contracts',
        component: InvoicesZcc,
        meta: {
          profiles: [Profile.SYS_ADMIN, Profile.ADMIN],
          iconClass: 'fa-light fa-file-invoice-dollar | fa fa-list-check',
          hidden: (window as any).maestroContextType === 'APP',
        },
      },
      {
        name: 'FISCAL_DOCUMENTS',
        path: '/plans-and-contracts/:contract_id/charging',
        component: FiscalDocumentsView,
        meta: {
          hidden: true,
          profiles: [Profile.SYS_ADMIN, Profile.ADMIN],
        },
      },
      {
        name: 'INVOICES_RECEIPTS_SUITE',
        path: '/financial',
        component: InvoicesReceiptsView,
        meta: {
          profiles: [Profile.SYS_ADMIN, Profile.ADMIN],
          iconClass: 'fa-light fa-file-invoice-dollar',
          featureName: 'new-customer-area',
          hidden: (window as any).maestroContextType === 'APP',
        },
        beforeEnter: (to, from, next) => {
          if ((window as any).maestroContextType === 'SUITE') {
            next();
          } else next('/payments/financial');
        },
      },
      {
        name: 'CHANGE_PLAN_ZCC',
        path: '/plans-and-contracts/change-plan',
        component: ChangePlanZCCView,
        meta: {
          hidden: (window as any).maestroContextType === 'APP',
          profiles: [Profile.SYS_ADMIN, Profile.ADMIN],
        },
      },
    ],
  },
];

export default routes;
