import Vue from 'vue';
import { PluginObject } from 'vue/types/umd';

import logsApiDirective from './logs-api';

const Directive: PluginObject<unknown> = {
  install: (instance: typeof Vue): void => {
    instance.directive('logs-api', logsApiDirective);
  },
};

export default Directive;
