

import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import DownloadDocumentMixin from '@/mixins/download-document';

import { IFiscalDocument } from '@/types/fiscal-documents';

@Component
export default class ActionDocumentsItem extends mixins(DownloadDocumentMixin) {
  @Prop({
    required: true,
    default: () => ({}),
    type: Object,
  }) readonly document!: IFiscalDocument;

  @Prop({
    required: true,
    default: '',
    type: String,
  }) readonly label!: string;

  @Prop({
    required: true,
    default: '',
    type: String,
  }) readonly type!: string;

  @Prop({
    required: false,
    default: 'fa-file',
    type: String,
  }) readonly icon!: string;
}
