export const AVAILABLE_PLANS = {
  BRL: [
    {
      id: 1,
      name: 'Starter',
      price: 0,
      users: 1,
      interactionz: 100,
      priceForExceededUsers: 250,
      priceForExceededInteratcionz: 2.50,
      activationPrice: 649,
    },
    {
      id: 2,
      name: 'Specialist',
      price: 600,
      users: 10,
      interactionz: 500,
      priceForExceededUsers: 150,
      priceForExceededInteratcionz: 1.32,
      activationPrice: 649,
    },
    {
      id: 3,
      name: 'Expert',
      price: 1800,
      users: 30,
      interactionz: 2000,
      priceForExceededUsers: 100,
      priceForExceededInteratcionz: 0.99,
      activationPrice: 1999,
    },
    {
      id: 4,
      name: 'Professional',
      price: 3900,
      users: 50,
      interactionz: 5000,
      priceForExceededUsers: 100,
      priceForExceededInteratcionz: 0.86,
      activationPrice: 3999,
    },

  ],
  USD: [
    {
      id: 1,
      name: 'Starter',
      price: 0,
      users: 1,
      interactionz: 100,
      priceForExceededUsers: 53,
      priceForExceededInteratcionz: 1,
      activationPrice: 137,
    },
    {
      id: 2,
      name: 'Specialist',
      price: 130,
      users: 10,
      interactionz: 500,
      priceForExceededUsers: 32,
      priceForExceededInteratcionz: 0.28,
      activationPrice: 137,
    },
    {
      id: 3,
      name: 'Expert',
      price: 390,
      users: 30,
      interactionz: 2000,
      priceForExceededUsers: 22,
      priceForExceededInteratcionz: 0.21,
      activationPrice: 421,
    },
    {
      id: 4,
      name: 'Professional',
      price: 845,
      users: 50,
      interactionz: 5000,
      priceForExceededUsers: 22,
      priceForExceededInteratcionz: 0.19,
      activationPrice: 842,
    },
  ],
  MXN: [
    {
      id: 1,
      name: 'Starter',
      price: 0,
      users: 1,
      interactionz: 100,
      priceForExceededUsers: 917,
      priceForExceededInteratcionz: 18,
      activationPrice: 2369,
    },
    {
      id: 2,
      name: 'Specialist',
      price: 2200,
      users: 10,
      interactionz: 500,
      priceForExceededUsers: 554,
      priceForExceededInteratcionz: 5,
      activationPrice: 2369,
    },
    {
      id: 3,
      name: 'Expert',
      price: 6600,
      users: 30,
      interactionz: 2000,
      priceForExceededUsers: 381,
      priceForExceededInteratcionz: 4,
      activationPrice: 7279,
    },
    {
      id: 4,
      name: 'Professional',
      price: 14300,
      users: 50,
      interactionz: 5000,
      priceForExceededUsers: 381,
      priceForExceededInteratcionz: 4,
      activationPrice: 14557,
    },
  ],
};

export const AVAILABLE_CHANNELS = {
  BRL: [
    {
      id: 1,
      price: 100,
      sms: 1000,
      whatsapp: 182,
      whatsappUser: 334,
      email: 25000,
      smsUnitPrice: 0.100,
      whatsappBusinessUnitPrice: 0.550,
      whatsappUserUnitPrice: 0.300,
      emailUnitPrice: 0.0040,
    },
    {
      id: 2,
      price: 250,
      sms: 2942,
      whatsapp: 472,
      whatsappUser: 893,
      email: 71429,
      smsUnitPrice: 0.085,
      whatsappBusinessUnitPrice: 0.530,
      whatsappUserUnitPrice: 0.280,
      emailUnitPrice: 0.0035,
    },
    {
      id: 3,
      price: 500,
      sms: 6250,
      whatsapp: 981,
      whatsappUser: 1924,
      email: 166667,
      smsUnitPrice: 0.080,
      whatsappBusinessUnitPrice: 0.510,
      whatsappUserUnitPrice: 0.260,
      emailUnitPrice: 0.0030,
    },
    {
      id: 4,
      price: 1000,
      sms: 13334,
      whatsapp: 2041,
      whatsappUser: 4167,
      email: 400000,
      smsUnitPrice: 0.075,
      whatsappBusinessUnitPrice: 0.490,
      whatsappUserUnitPrice: 0.240,
      emailUnitPrice: 0.0025,
    },
    {
      id: 5,
      price: 2000,
      sms: 28572,
      whatsapp: 4256,
      whatsappUser: 9091,
      email: 1000000,
      smsUnitPrice: 0.070,
      whatsappBusinessUnitPrice: 0.470,
      whatsappUserUnitPrice: 0.220,
      emailUnitPrice: 0.0020,
    },
  ],
  USD: [
    {
      id: 1,
      price: 20,
      sms: 1088,
      whatsapp: 431,
      whatsappUser: 1079,
      email: 23750,
      smsUnitPrice: 0.0184,
      whatsappBusinessUnitPrice: 0.0463,
      whatsappUserUnitPrice: 0.0185,
      emailUnitPrice: 0.00084,
    },
    {
      id: 2,
      price: 50,
      sms: 3202,
      whatsapp: 1120,
      whatsappUser: 2891,
      email: 67857,
      smsUnitPrice: 0.0156,
      whatsappBusinessUnitPrice: 0.0446,
      whatsappUserUnitPrice: 0.0173,
      emailUnitPrice: 0.00074,
    },
    {
      id: 3,
      price: 100,
      sms: 6804,
      whatsapp: 2328,
      whatsappUser: 6228,
      email: 158333,
      smsUnitPrice: 0.0147,
      whatsappBusinessUnitPrice: 0.0429,
      whatsappUserUnitPrice: 0.0161,
      emailUnitPrice: 0.00063,
    },
    {
      id: 4,
      price: 200,
      sms: 14517,
      whatsapp: 4842,
      whatsappUser: 13494,
      email: 380000,
      smsUnitPrice: 0.0138,
      whatsappBusinessUnitPrice: 0.0413,
      whatsappUserUnitPrice: 0.0148,
      emailUnitPrice: 0.00053,
    },
    {
      id: 5,
      price: 400,
      sms: 31108,
      whatsapp: 10106,
      whatsappUser: 29442,
      email: 950000,
      smsUnitPrice: 0.0129,
      whatsappBusinessUnitPrice: 0.0396,
      whatsappUserUnitPrice: 0.0136,
      emailUnitPrice: 0.00042,
    },
  ],
  MXN: [
    {
      id: 2,
      price: 400,
      sms: 137,
      whatsapp: 297,
      whatsappUser: 1085,
      email: 27475,
      smsUnitPrice: 2.9071,
      whatsappBusinessUnitPrice: 1.3465,
      whatsappUserUnitPrice: 0.3685,
      emailUnitPrice: 0.0146,
    },
    {
      id: 2,
      price: 1000,
      sms: 404,
      whatsapp: 770,
      whatsappUser: 2907,
      email: 78500,
      smsUnitPrice: 2.470,
      whatsappBusinessUnitPrice: 1.2976,
      whatsappUserUnitPrice: 0.3439,
      emailUnitPrice: 0.0127,
    },
    {
      id: 3,
      price: 2000,
      sms: 859,
      whatsapp: 1601,
      whatsappUser: 6262,
      email: 183167,
      smsUnitPrice: 2.3257,
      whatsappBusinessUnitPrice: 1.2486,
      whatsappUserUnitPrice: 0.3194,
      emailUnitPrice: 0.0109,
    },
    {
      id: 4,
      price: 4000,
      sms: 1834,
      whatsapp: 3334,
      whatsappUser: 13568,
      email: 439601,
      smsUnitPrice: 2.1803,
      whatsappBusinessUnitPrice: 1.1996,
      whatsappUserUnitPrice: 0.2948,
      emailUnitPrice: 0.0091,
    },
    {
      id: 5,
      price: 8000,
      sms: 3931,
      whatsapp: 6952,
      whatsappUser: 29604,
      email: 1099004,
      smsUnitPrice: 2.0350,
      whatsappBusinessUnitPrice: 1.1507,
      whatsappUserUnitPrice: 0.2702,
      emailUnitPrice: 0.0073,
    },
  ],
};

export const PLANS_CHANNELS_COMBINATIONS = [
  {
    planId: 'd3ddb9cc-e535-4eab-9144-3a6c6312bdde',
    softwarePlanId: 1,
    channelPlanId: 1,
  },
  {
    planId: '437548d3-5e3e-49af-a2ce-0ac9f17a4c38',
    softwarePlanId: 1,
    channelPlanId: 2,
  },
  {
    planId: '5ddf89d1-178a-42bf-8a23-75c97fd9d10d',
    softwarePlanId: 1,
    channelPlanId: 3,
  },
  {
    planId: '3d2f5f6a-8bfd-4dc0-978d-2ae6fdbfe616',
    softwarePlanId: 1,
    channelPlanId: 4,
  },
  {
    planId: 'fb253f3b-9833-4198-a1fc-99ea76ff78e0',
    softwarePlanId: 1,
    channelPlanId: 5,
  },
  {
    planId: '9f448058-a32f-4998-9cff-992a15076396',
    softwarePlanId: 2,
    channelPlanId: 1,
  },
  {
    planId: '11b1ba1a-616a-4bee-9228-46eda4ba0ddc',
    softwarePlanId: 2,
    channelPlanId: 2,
  },
  {
    planId: '38c70a98-2ede-409a-8252-2210e4da12d8',
    softwarePlanId: 2,
    channelPlanId: 3,
  },
  {
    planId: '09ec50c2-7f07-45ab-bed8-0f7cd6f99097',
    softwarePlanId: 2,
    channelPlanId: 4,
  },
  {
    planId: '929228de-5647-415d-b4df-c6481c59b379',
    softwarePlanId: 2,
    channelPlanId: 5,
  },
  {
    planId: '4bb17536-9be3-467c-a592-273e9ee206e4',
    softwarePlanId: 3,
    channelPlanId: 1,
  },
  {
    planId: '622a89be-19c1-46ea-9b86-9be28b5efd5d',
    softwarePlanId: 3,
    channelPlanId: 2,
  },
  {
    planId: 'd3c4abad-e706-4226-ad40-e5689d509273',
    softwarePlanId: 3,
    channelPlanId: 3,
  },
  {
    planId: '320c8696-9404-41e4-b076-03039e6a0dcd',
    softwarePlanId: 3,
    channelPlanId: 4,
  },
  {
    planId: 'ff88e985-997c-4497-9669-78e738eb0a4d',
    softwarePlanId: 3,
    channelPlanId: 5,
  },
  {
    planId: 'df0ff164-6d80-429b-9854-85d534292033',
    softwarePlanId: 4,
    channelPlanId: 1,
  },
  {
    planId: '2236e464-a969-4f12-9e07-777fd01c9930',
    softwarePlanId: 4,
    channelPlanId: 2,
  },
  {
    planId: 'bc834462-2479-4f94-91e9-24195009a4e6',
    softwarePlanId: 4,
    channelPlanId: 3,
  },
  {
    planId: '30e12852-ae5d-4a52-8ee1-94a58fd15dc8',
    softwarePlanId: 4,
    channelPlanId: 4,
  },
  {
    planId: '8df8c3a4-b0e6-4d51-ba6e-31cde1882f93',
    softwarePlanId: 4,
    channelPlanId: 5,
  },
];
