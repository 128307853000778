
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import CheckBox from '@/components/CheckBox.vue';
import Badges from '@/components/Badges.vue';
import Buttons from '@/components/Buttons.vue';

interface Item {
  id: string;
  name: string;
  isDisabled: boolean;
  isChecked: boolean;
  isDisabledCheckbox: boolean;
  iconDetailedConsumption: { isDisabled: boolean }[];
  iconInvoice: { isDisabled: boolean }[];
  iconBill: { isDisabled: boolean }[];
}

interface ButtonItem {
  id: string;
  label: string;
  icon: string,
  dataTrack: string;
  buttonDownloadAction: () => void;
}

@Component({
  components: {
    CheckBox,
    Badges,
    Buttons,
  },
})

export default class TableInvoiceReceipts extends Vue {
  @Prop({ required: true, type: Array }) itens!: Item[];

  @Prop({
    required: true,
    type: Array,
  }) readonly headers!: object[];

  @Prop({
    required: false,
    type: Boolean,
  }) readonly areAllChecked!: boolean;

  @Prop({
    required: false,
    type: Number,
  }) readonly selectedCount!: number;

  @Prop({
    required: false,
    type: String,
  }) readonly country!: string;

  @Prop({ type: Function }) selectAllCheckboxes!: () => void;

  @Prop({ type: Function }) clearAllCheckboxes!: () => void;

  @Prop({ type: Function }) clearSelectedCheckboxes!: () => void;

  @Prop({ type: Function }) toggleCheckbox!: () => void;

  private showSecondButton = false;

  private sortByField = '';

  private isIconActiveContracts = true;

  private isIconActiveValue = true;

  private isIconActiveDueDate = true;

  private isIconActiveStatus = true;

  toggleSecondSpan() {
    this.showSecondButton = !this.showSecondButton;
  }

  handlerIsActiveIconContracts() {
    this.isIconActiveContracts = !this.isIconActiveContracts;
  }

  handlerIsActiveIconValue() {
    this.isIconActiveValue = !this.isIconActiveValue;
  }

  handlerIsActiveIconDueDate() {
    this.isIconActiveDueDate = !this.isIconActiveDueDate;
  }

  handlerIsActiveIconStatus() {
    this.isIconActiveStatus = !this.isIconActiveStatus;
  }

  get buttonClass() {
    return !this.selectedCount && !this.showSecondButton ? 'is-text has-text-grey-dark' : 'is-text has-text-primary';
  }

  downloadItem(item: any, downloadFunction: () => void): void {
    if (!item.isDisabled) {
      downloadFunction();
    }
  }

  private handleDownloadAction(actionType: string, itemType: string): void {
    const iconInfo = this.itens
      .filter((item: any) => item.isChecked)
      .map((item: any) => item[itemType]);
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const filteredIconInfo = iconInfo.filter(({ type, url }: { type: string, url: string }) => {
      let labelType = '';

      if (type === itemType) {
        labelType = itemType;
      } else if (type === 'MX_XML_FATURA') {
        labelType = 'MX_XML_FATURA';
      } else if (type === 'MX_FACTURA') {
        labelType = 'MX_FACTURA';
      } else if (type === 'AR_FACTURA') {
        labelType = 'AR_FACTURA';
      } else if (url && url.length > 0) {
        labelType = 'invoiceSVA';
      }

      if (labelType) {
        this.$emit(`download-${actionType}`, `${url}: ${labelType}`, labelType);
        return false;
      }

      return true;
    });
  }

  private handlerDownloadActionBill(): void {
    this.handleDownloadAction('bill', 'iconBill');
  }

  private handlerDownloadActionInvoice(): void {
    this.handleDownloadAction('invoice', 'iconInvoice');
  }

  private handlerDownloadActionDetailedConsumption(): void {
    this.handleDownloadAction('detailed-consumption', 'iconDetailedConsumption');
  }

  itensButton: Array<ButtonItem> = [
    {
      id: 'detailedConsumption',
      label: `${this.$t('INVOICES_RECEIPTS.ACTION_BUTTONS.DETAILING')}`,
      icon: '',
      dataTrack: 'invoices-bulk_actions_btn-all_detailings',
      buttonDownloadAction: this.handlerDownloadActionDetailedConsumption,
    },
    {
      id: 'invoice',
      label: `${this.$t('INVOICES_RECEIPTS.ACTION_BUTTONS.INVOICE')}`,
      icon: '',
      dataTrack: 'invoices-bulk_actions_btn-all_NFe',
      buttonDownloadAction: this.handlerDownloadActionInvoice,
    },
    {
      id: 'bill',
      label: `${this.$t('INVOICES_RECEIPTS.ACTION_BUTTONS.TICKET')}`,
      icon: '',
      dataTrack: 'invoices-bulk_actions_btn-all_invoices',
      buttonDownloadAction: this.handlerDownloadActionBill,
    },
  ]
}
