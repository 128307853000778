import { RouteConfig } from 'vue-router';

import DefaultLayout from '@/layouts/Default.vue';

import preferences from './preferences';
import payments from './payments';

const routes: Array<RouteConfig> = [
  {
    name: 'Root',
    path: '/',
    redirect: '/preferences',
    component: DefaultLayout,
    meta: { hidden: true },
  },
  ...payments,
  ...preferences,
];

export default routes;
