import { eventBus, Events } from '@/plugins/event-bus';

export class Loading {
  public show(message = '') {
    eventBus.$emit(Events.LoadingShow, message);
  }

  public hide() {
    eventBus.$emit(Events.LoadingHide);
  }
}

const loading = new Loading();

export default loading;
