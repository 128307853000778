
import { Component, Vue } from 'vue-property-decorator';
import { AxiosRequestConfig } from 'axios';

@Component
export default class TabAccount extends Vue {
  public activeTabItem = 'my-account';

  public showAddressTab = false;

  private get findCustomerUrl() {
    return `${process.env.VUE_APP_CUSTOMER_PERSONAL_DATA_URL}/v1/customer`;
  }

  private mounted() {
    this.fillShowAddressTab();
  }

  public isActive(item: string) {
    return this.activeTabItem === item;
  }

  public setActiveItem(item: string) {
    this.activeTabItem = item;
  }

  private async fillShowAddressTab() {
    try {
      const config: AxiosRequestConfig = {
        validateStatus: (status: number) => (status >= 200 && status < 400) || status === 404,
      };
      const data = await this.$http.get(this.findCustomerUrl, config);
      this.showAddressTab = data.status !== 404;
    } catch (e) {
      this.$alert?.danger({
        title: `${this.$t('MESSAGES.ATTEMPTION')}`,
        message: `${this.$t('MESSAGES.ERROR_TRY_AGAIN')}`,
        duration: 5000,
      });
    }
  }
}

