
import { Component, Prop, Vue } from 'vue-property-decorator';

import { IAddress } from '@/types/address';

import AddressLine from './AddressLine.vue';

@Component({
  components: { AddressLine },
})
export default class Address extends Vue {
  @Prop({
    required: true,
    type: Object,
  }) readonly address!: IAddress;
}
