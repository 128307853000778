var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"field"},[_c('label',{staticClass:"label",class:{
      'has-text-danger': _vm.error
    },attrs:{"id":`${_vm.id}-label`,"testid":`label-${_vm.id}`}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('p',{staticClass:"control",class:{
      'is-loading': _vm.loading
    },attrs:{"id":`${_vm.id}-control`,"testid":`control-${_vm.id}`}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.inputValue),expression:"inputValue",modifiers:{"trim":true}}],staticClass:"input",class:{
        'is-danger': _vm.error
      },attrs:{"id":`${_vm.id}-data`,"testid":`input-${_vm.id}`,"type":_vm.type,"placeholder":_vm.placeholder,"disabled":_vm.disabled || _vm.loading,"maxlength":_vm.maxlength},domProps:{"value":(_vm.inputValue)},on:{"input":function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('label',{staticClass:"label",class:{
      'has-text-danger': _vm.error
    },attrs:{"id":`${_vm.id}-helper`,"test-id":`helper-${_vm.id}`}},[(_vm.error)?_c('i',{staticClass:"fa-light fa-circle-minus has-text-danger"}):_vm._e(),_c('span',{class:{ 'helper-text': _vm.error }},[_vm._v(_vm._s(_vm.helperText))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }