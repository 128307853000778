
import { Component, Prop, Vue } from 'vue-property-decorator';

import Skeleton from './Skeleton.vue';

@Component({
  components: { Skeleton },
})
export default class SkeletonTable extends Vue {
  @Prop({
    required: true,
    default: 1,
    type: Number,
  }) readonly columns!: number;

  @Prop({
    required: false,
    default: 1,
    type: Number,
  }) readonly rows!: number;

  get headers() {
    return new Array(this.columns || 1);
  }

  get itens() {
    return new Array(this.rows || 1);
  }
}

