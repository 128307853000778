
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Checkbox extends Vue {
  @Prop({
    required: true,
    type: String,
  }) readonly id!: string;

  @Prop({
    required: true,
    type: [String, Boolean],
  }) readonly value!: string | boolean;

  @Prop({
    required: true,
    type: String,
  }) readonly label!: string;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly error!: boolean;

  @Prop({
    required: false,
    type: String,
    default: '',
  }) readonly errorMessage!: string;

  @Prop({
    required: false,
    type: String,
    default: '',
  }) readonly helper!: string;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly loading!: boolean;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly disabled!: boolean;

  public valueCheckbox = '';

  @Watch('valueCheckbox')
  private changeValue(newValue: string) {
    this.valueCheckbox = newValue;
  }

  @Watch('valueCheckbox')
  private changeInputValue(newValue: string) {
    this.$emit('input', newValue);
  }

  public get helperText(): string {
    return (this.error && this.errorMessage) || this.helper;
  }
}
