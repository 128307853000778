import Vue from 'vue';
import { PluginObject } from 'vue/types/umd';

import { IEventBus } from './type';

export const eventBus: IEventBus = new Vue();

const Plugin: PluginObject<unknown> = {
  install: (intance: typeof Vue): void => {
    Object.defineProperties(intance.prototype, {
      $eventBus: {
        get() {
          return eventBus;
        },
      },
    });
  },
};

export default Plugin;
