var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"field"},[_c('label',{staticClass:"label",class:{
      'has-text-danger': _vm.error
    },attrs:{"id":`${_vm.id}-label`,"testid":`label-${_vm.id}`}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"control",class:{
      'is-loading': _vm.loading
    },attrs:{"id":`${_vm.id}-control`,"testid":`control-${_vm.id}`}},[_c('div',{staticClass:"select is-block"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"input",class:{
          'is-danger': _vm.error
        },attrs:{"id":`${_vm.id}-data`,"testid":`select-${_vm.id}`,"disabled":_vm.disabled || _vm.loading},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.options),function(option,index){return _c('option',{key:index,attrs:{"id":`${_vm.id}-option${index}`,"testid":`option${index}-${_vm.id}`},domProps:{"value":option.value || option.label}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0)])]),_c('label',{staticClass:"help",class:{
      'has-text-danger': _vm.error
    },attrs:{"id":`${_vm.id}-helper`,"testid":`helper-${_vm.id}`}},[(_vm.error)?_c('i',{staticClass:"fa-light fa-circle-minus has-text-danger"}):_vm._e(),_c('span',{class:{ 'helper-text': _vm.error }},[_vm._v(_vm._s(_vm.helperText))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }