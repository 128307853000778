
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Input extends Vue {
  @Prop({
    required: true,
    type: String,
  }) readonly id!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly value!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly label!: string;

  @Prop({
    required: false,
    type: String,
    default: 'text',
  }) readonly type!: string;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly loading!: boolean;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly disabled!: boolean;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly error!: boolean;

  @Prop({
    required: false,
    type: String,
    default: '',
  }) readonly errorMessage!: string;

  @Prop({
    required: false,
    type: String,
    default: '',
  }) readonly placeholder!: string;

  @Prop({
    required: false,
    type: String,
    default: '',
  }) readonly helper!: string;

  @Prop({
    required: false,
    type: String,
    default: '',
  }) readonly maxlength!: string;

  public inputValue = '';

  public get helperText(): string {
    return (this.error && this.errorMessage) || this.helper;
  }

  @Watch('value')
  private changeValue(newValue: string) {
    this.inputValue = newValue;
  }

  @Watch('inputValue')
  private changeInputValue(newValue: string) {
    this.$emit('input', newValue);
  }
}
