
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconBase from '@/components/IconBase.vue';

@Component({
  components: {
    IconBase,
  },
})

export default class RestrictionPage extends Vue {
  @Prop({
    required: false,
    type: String,
  }) readonly title!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly subtitle!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly iconRestriction!: string;
}
