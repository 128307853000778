
import { Component, Vue } from 'vue-property-decorator';

import { Events } from '@/plugins';

@Component({
  components: { },
})
export default class Loading extends Vue {
  private active = false;

  private message = '';

  created() {
    this.$eventBus.$on(Events.LoadingShow, this.show);
    this.$eventBus.$on(Events.LoadingHide, this.hide);
  }

  destroy() {
    this.$eventBus.$off(Events.LoadingShow, this.show);
    this.$eventBus.$off(Events.LoadingHide, this.hide);
  }

  private show(message = ''): void {
    this.message = message;
    this.active = true;
  }

  private hide(): void {
    this.message = '';
    this.active = false;
  }
}
