
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import { formatCurrency } from '@/utils/numberFormats';
import PlanCapabilities from './PlanCapabilities.vue';

interface SoftwarePlanData {
  id: number;
  price: number;
  users: number;
  interactionz: number;
  priceForExceededUsers: number
  priceForExceededInteratcionz: number;
}

interface ChannelPlanData {
  id: number;
  price: number;
  sms: number;
  whatsapp: number;
  email: number;
}

interface CardData {
  currency: string;
  softwarePlan: SoftwarePlanData;
  channelPlan: ChannelPlanData;
}

@Component({
  components: {
    PlanCapabilities,
  },
})
export default class PlanSummaryCard extends Vue {
  @Prop({
    required: false,
    default: () => ({}),
    type: Object,
  }) private cardData!: CardData;

  @Prop({
    required: true,
    default: () => false,
    type: Boolean,
  }) private isButtonDisabled!: boolean;

  @Prop({
    required: true,
    default: () => false,
    type: Boolean,
  }) private isButtonLoading!: boolean;

  formatCurrency = formatCurrency;

  handleSubmitPlanChange() {
    this.$emit('onSubmitPlanChange');
  }
}
