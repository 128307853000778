
import Component, { mixins } from 'vue-class-component';

import { namespace } from 'vuex-class';

import ContractStore from '@/store/modules/contract';

import SessionMixin from '@/mixins/session';

import PageHeader from '@/components/PageHeader.vue';
import AsyncProvider from '@/components/AsyncProvider.vue';
import Skeleton from '@/components/Skeleton.vue';
import Hint from '@/components/Hint.vue';
import Breadcrumb, { IBreadcrumb } from '@/components/Breadcrumb.vue';
import { IContract } from '@/types/contract';

import CardContract from './components/CardContract.vue';

const contract = namespace('contract');

@Component({
  components: {
    PageHeader,
    CardContract,
    AsyncProvider,
    Skeleton,
    Hint,
    Breadcrumb,
  },
})

export default class InvoicesView extends mixins(SessionMixin) {
  private once = false;

  public showHintForNewCustomerArea = false;

  public returnurl = `${process.env.VUE_APP_PAYMENT_HISTORY_URL}/v3/contract`;

  public loading = true;

  public maestroContextType = (window as any).maestroContextType;

  async created() {
    this.showHintForNewCustomerArea = await this.$canIViewFeature({ name: 'hint-for-new-customer-area' });

    await (this.$refs.contractsProvider as AsyncProvider).requestData();
    this.loading = false;
  }

  public get url(): string {
    return this.returnurl;
  }

  public get params() {
    return {
      organizationId: this.mxSession_organization.id,
      email: this.mxSession_user.email,
    };
  }

  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        labelLink: `${this.$t('INVOICES_RECEIPTS.BREADCRUMBS.HOME')}`,
        path: '/welcome',
      },
      {
        label: `${this.$t('INVOICES_RECEIPTS.BREADCRUMBS.CUSTOMER_AREA')}`,
        to: '/preferences/my-account',
      },
      {
        label: this.$tc('MENU_LEFT.INVOICES', 1).toString(),
        to: '#',
      },
    ];
  }

  @contract.Mutation
  public setContract!: (newContract: ContractStore) => void;

  public handleSetContract(data: ContractStore) {
    this.setContract(data);

    let routeUrl = '/payments/contracts';
    if (this.maestroContextType === 'SUITE') routeUrl = '/plans-and-contracts';

    this.$router.push(`${routeUrl}/${data?.contractID || data?.salesforceContractID}/charging`);
  }

  public afterRequest(data: IContract[]) {
    this.once = true;
    if (data && data.length === 1) {
      const [singleContract] = data;

      this.setContract({ ...singleContract, isSingleContract: true } as ContractStore);

      let routeUrl = '/payments/contracts';
      if (this.maestroContextType === 'SUITE') routeUrl = '/plans-and-contracts';
      this.$router.push(`${routeUrl}/${singleContract.contractID || singleContract.salesforceContractID}/charging`);
    }
  }

  public goToNewCustomerArea() {
    window.open(process.env.VUE_APP_NEW_CUSTOMER_AREA_URL);
  }

  // eslint-disable-next-line
  public orderByStatus(data: IContract[]) {
    if (!this.once) {
      return data.sort((contract1: IContract, contract2: IContract) => {
        if (contract1.status === 'Activated') { return -2; }
        if (contract2.status === 'Activated') { return 2; }
        if (contract1.status < contract2.status) { return -1; }
        if (contract1.status > contract2.status) { return 1; }
        return 0;
      });
    }

    return data;
  }
}

