import './set-public-path';

import 'animate.css';

import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import Vuelidate from 'vuelidate';

// @ts-expect-error:
import auth from '@platform/authentication';

import { init as initLogger } from 'logs-api-client';

import {
  axios,
  events,
  alert,
  featureToggle,
  loading,
} from './plugins';

import { logsApi } from './directives';

import i18n from './language';
import router from './router';
import store from './store';

import App from './App.vue';

Vue.config.productionTip = false;

initLogger({
  appName: process.env.VUE_APP_BASE_URL,
  logsApiUrl: process.env.VUE_APP_LOGGER_URL,
  getSession: auth.getSession,
});

Vue.use(axios);
Vue.use(events);
Vue.use(alert);
Vue.use(featureToggle);
Vue.use(loading);

Vue.use(Vuelidate);
Vue.use(logsApi);

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h: Function) => h(App),
    router,
    store,
    i18n,
  },
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
