
import Component, { mixins } from 'vue-class-component';
// @ts-expect-error:
import FeedbackService from '@platform/feedback-service';
import { namespace } from 'vuex-class';
import ZccContractStore from '@/store/modules/zccContract';
import Breadcrumb, { IBreadcrumb } from '@/components/Breadcrumb.vue';
import { getClickWrapId } from '@/utils/clickWrapUtils';
import SessionMixin from '@/mixins/session';
import SelectPlanCard from './components/SelectPlanCard.vue';
import PlanSummaryCard from './components/PlanSummaryCard.vue';
import { AVAILABLE_PLANS, AVAILABLE_CHANNELS, PLANS_CHANNELS_COMBINATIONS } from '../../utils/plans';
import ChannelPlanDetails from './components/ChannelPlanDetails.vue';

const zccContractNamespace = namespace('zccContract');

@Component({
  components: {
    Breadcrumb,
    SelectPlanCard,
    PlanSummaryCard,
    ChannelPlanDetails,
  },
})

export default class ChangePlanZCCView extends mixins(SessionMixin) {
  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        labelLink: `${this.$t('CHANGE_PLAN_ZCC.HEADER.BREADCRUMBS.HOME')}`,
        path: '/welcome',
      },
      {
        label: `${this.$t('CHANGE_PLAN_ZCC.HEADER.BREADCRUMBS.PLANS')}`,
        to: '/plans-and-contracts',
      },
      {
        label: this.$tc('CHANGE_PLAN_ZCC.HEADER.BREADCRUMBS.CHANGE_PLAN', 1).toString(),
        to: '#',
      },
    ];
  }

  @zccContractNamespace.Getter
  public zccContract!: ZccContractStore;

  private selectedType = 'software';

  private defaultSelectedPlan: any = {};

  private defaultSelectedChannel: any = {};

  private softwarePlanSelected: any = {};

  private channelPlanSelected: any = {};

  private softwarePlanData = {};

  private channelPlanData = {};

  private isChangePlanButtonLoading = false;

  onCardCliked(type: string, item: any) {
    this.selectedType = type;
    if (type === 'software') {
      this.softwarePlanSelected = item;
    }

    if (type === 'channel') {
      this.channelPlanSelected = item;
    }
  }

  formatAssignmentDate(date: string) {
    const formattedDate = new Date(date);
    return formattedDate.toISOString().slice(0, 19).replace('T', ' ');
  }

  async onRequestDowngradeToStarter() {
    const uri = `${process.env.VUE_APP_CUSTOMER_ORDER_MANAGER}/v1/plans/request-downgrade-to-starter`;
    let status = 'success';
    let message = 'SUCCESS';

    try {
      await this.$http.post(uri, { contractNumber: this.zccContract.salesforceContractId });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      message = 'ERROR';
      status = 'danger';
    }
    this.$router.push('/plans-and-contracts');
    FeedbackService.toastr.show(status, this.$t(`CHANGE_PLAN_ZCC.HINT_BOX.FEEDBACK_${message}`));
  }

  async onTermAgree(agreedOn: string, clientUserId: string) {
    const uri = `${process.env.VUE_APP_CUSTOMER_ORDER_MANAGER}/v1/selfservice/plan/request-change`;
    let status = 'success';
    let message = 'SUCCESS';

    try {
      await this.$http.post(uri, {
        contractId: this.zccContract.salesforceContractId,
        planId: PLANS_CHANNELS_COMBINATIONS.find((combination) => combination.channelPlanId === this.channelPlanSelected.id
          && combination.softwarePlanId === this.softwarePlanSelected.id)?.planId,
        status: 'PENDING',
        dateAssignment: this.formatAssignmentDate(agreedOn),
        email: clientUserId,
        currencyIsoCode: this.zccContract.currency,
      });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      message = 'ERROR';
      status = 'danger';
    }
    this.$router.push('/plans-and-contracts');
    FeedbackService.toastr.show(status, this.$t(`CHANGE_PLAN_ZCC.FEEDBACK.${message}`));
  }

  mountPlanData() {
    this.channelPlanData = {
      title: this.$t('CHANGE_PLAN_ZCC.SELECT_PLAN_CARD.CHANNEL_PLAN_TITLE'),
      type: 'channel',
      currency: this.zccContract.currency,
      data: AVAILABLE_CHANNELS[this.zccContract.currency as keyof typeof AVAILABLE_CHANNELS],
    };

    this.softwarePlanData = {
      title: this.$t('CHANGE_PLAN_ZCC.SELECT_PLAN_CARD.SOFTWARE_PLAN_TITLE'),
      type: 'software',
      currency: this.zccContract.currency,
      data: AVAILABLE_PLANS[this.zccContract.currency as keyof typeof AVAILABLE_PLANS],
    };
  }

  async mounted() {
    const canViewChangePlan = await this.$canIViewFeature({ name: 'upgrade-downgrade-feature' });
    if (canViewChangePlan && (!this.zccContract || this.zccContract.status !== 'ACTIVE')) {
      this.$router.push('/plans-and-contracts');
    }
    const planCombination = PLANS_CHANNELS_COMBINATIONS.find((combination) => combination.planId === this.zccContract.planId);
    if (planCombination?.softwarePlanId && planCombination.channelPlanId) {
      this.defaultSelectedPlan = AVAILABLE_PLANS[this.zccContract.currency as keyof typeof AVAILABLE_PLANS].find((plan) => plan.id === planCombination?.softwarePlanId);
      this.defaultSelectedChannel = AVAILABLE_CHANNELS[this.zccContract.currency as keyof typeof AVAILABLE_CHANNELS].find((channel) => channel.id === planCombination?.channelPlanId);
    } else {
      // eslint-disable-next-line prefer-destructuring
      this.defaultSelectedPlan = AVAILABLE_PLANS[this.zccContract.currency as keyof typeof AVAILABLE_PLANS][0];
      // eslint-disable-next-line prefer-destructuring
      this.defaultSelectedChannel = AVAILABLE_CHANNELS[this.zccContract.currency as keyof typeof AVAILABLE_CHANNELS][0];
    }
    this.softwarePlanSelected = this.defaultSelectedPlan;
    this.channelPlanSelected = this.defaultSelectedChannel;
    this.mountPlanData();
  }

  renderClickWrapModal() {
    this.isChangePlanButtonLoading = true;
    (window as any).docuSignClick.Clickwrap.render({
      environment: process.env.VUE_APP_DOCUSIGN_ENVIRONMENT_URL,
      accountId: process.env.VUE_APP_DOCUSIGN_ACCOUNT_ID,
      clickwrapId: getClickWrapId(this.zccContract.country),
      clientUserId: this.mxSession_user.email,
      documentData: {
        fullName: this.mxSession_user.name,
        email: this.mxSession_user.email,
        company: this.mxSession_organization.name,
      },
      style: {
        agreeButton: {
          color: 'white',
          backgroundColor: '#5a4cfb',
          width: '100%',
          borderRadius: '0px',
        },
        container: {
          borderRadius: '10px',
        },
      },
      onAgreed: (response: any) => {
        this.onTermAgree(response.agreedOn, response.clientUserId);
        this.isChangePlanButtonLoading = false;
      },
      onMustAgree: () => {
        this.isChangePlanButtonLoading = false;
      },
    }, '#clickwrap-terms-of-service');
  }
}
