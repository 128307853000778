/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { DirectiveOptions, DirectiveBinding } from 'vue/types/options';

import { logger } from 'logs-api-client';

const directiveOptions: DirectiveOptions = {
  inserted(el: HTMLElement, binding: DirectiveBinding) {
    const message = `${binding.arg} - ${binding.value}`;
    const keys = { el: el.outerHTML };

    Object.keys(binding.modifiers)
      .forEach((event: string) => {
        el.addEventListener(event, () => logger.info(message, { ...keys, event }));
      });
  },
};

export default directiveOptions;
