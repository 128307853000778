import { eventBus, Events } from '@/plugins/event-bus';

export interface IAlertOptions {
  title?: string;
  message: string;
  duration?: number;
}

export class Alert {
  public danger(options: IAlertOptions) {
    this.emitEvent(options, 'danger');
  }

  public success(options: IAlertOptions) {
    this.emitEvent(options, 'success');
  }

  public warning(options: IAlertOptions) {
    this.emitEvent(options, 'warning');
  }

  private emitEvent(options: IAlertOptions, type: string) {
    eventBus.$emit(Events.Alert, { duration: 3000, ...options, type });
  }
}

const alert = new Alert();

export default alert;
