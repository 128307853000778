import {
  VuexModule,
  Module,
  Mutation,
} from 'vuex-module-decorators';

import { ZccContractStore } from '@/types/contract-zcc';

@Module({ namespaced: true, name: 'contract' })
class ZccContract extends VuexModule implements ZccContractStore {
  public zenviaSubscriptionCode = '';

  public country = '';

  public currency = '';

  public planId = '';

  public status = ''

  public salesforceContractId = ''

  get zccContract(): ZccContract {
    return {
      zenviaSubscriptionCode: this.zenviaSubscriptionCode,
      country: this.country,
      currency: this.currency,
      planId: this.planId,
      status: this.status,
      salesforceContractId: this.salesforceContractId,
    } as ZccContract;
  }

  @Mutation
  public setZccContract(contract: ZccContract) {
    const {
      zenviaSubscriptionCode,
      country,
      currency,
      planId,
      status,
      salesforceContractId,
    } = contract;

    this.zenviaSubscriptionCode = zenviaSubscriptionCode;
    this.country = country;
    this.currency = currency;
    this.planId = planId;
    this.status = status;
    this.salesforceContractId = salesforceContractId;
  }
}

export default ZccContract;
