
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface IitensPagination {
  current: boolean;
  ariaLabel: string;
  ariaCurrent: string;
  numberPage: number;
  itemsCount: number;
  clickPage?: () => void;
}

@Component
export default class PaginationTable extends Vue {
  @Prop({
    required: true,
    type: String,
  })
  readonly labelPagination!: string;

  @Prop({
    required: true,
    default: () => [],
  })
  paths!: IitensPagination[];

  get itens(): IitensPagination[] {
    return this.paths || [];
  }
}
