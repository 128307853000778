import loading, { Loading } from './loading';

import Plugin from './plugin';

declare module 'vue/types/vue' {
  interface Vue {
    $loading: Loading;
  }
}

export default Plugin;

export { loading, Loading };
