

import { Component, Prop } from 'vue-property-decorator';

import { mixins } from 'vue-class-component';

import DownloadDocumentMixin from '@/mixins/download-document';

import { IFiscalDocument } from '@/types/fiscal-documents';

@Component({})
export default class ActionDocumentsPayableItem extends mixins(DownloadDocumentMixin) {
  @Prop({
    required: true,
    default: () => ({}),
    type: Object,
  }) readonly document!: IFiscalDocument;

  @Prop({
    required: true,
    default: '',
    type: String,
  }) readonly title!: string;

  @Prop({
    required: true,
    default: '',
    type: String,
  }) readonly subTitle!: string;

  get situation(): string {
    try {
    // @ts-ignore
      const label = this.statusLabel[this.document.status] || 'OPENED';
      return this.$t(`FISCAL_DOCUMENTS.PAYMENT_HISTORY.STATUS.${label}`).toString();
    } catch (e) {
      return ' - ';
    }
  }

  get situationType(): string {
    try {
    // @ts-ignore
      return this.statusType[this.document.status] || 'warning';
    } catch (e) {
      return 'warning';
    }
  }

  get color(): string {
    try {
    // @ts-ignore
      return this.mainColor[this.document.status] || 'primary';
    } catch (e) {
      return 'primary';
    }
  }

  private mainColor = {
    QUITADO: 'success',
    EM_ABERTO: 'primary',
  };

  private statusType = {
    QUITADO: 'success',
    EM_ABERTO: 'warning',
  };

  private statusLabel = {
    QUITADO: 'PAID',
    EM_ABERTO: 'OPENED',
  }
}
