import {
  VuexModule,
  Module,
  Mutation,
} from 'vuex-module-decorators';

import { IContract } from '@/types/contract';

@Module({ namespaced: true, name: 'contract' })
class Contract extends VuexModule implements IContract {
  public products = [];

  public contractID = '';

  public salesforceContractID = '';

  public status = '';

  public isSingleContract = false;

  get contract(): Contract {
    return {
      products: this.products,
      contractID: this.contractID,
      salesforceContractID: this.salesforceContractID,
      status: this.status,
      isSingleContract: this.isSingleContract,
    } as Contract;
  }

  @Mutation
  public setContract(contract: Contract) {
    const {
      products,
      contractID,
      salesforceContractID,
      status,
      isSingleContract,
    } = contract;

    this.products = products;
    this.contractID = contractID;
    this.salesforceContractID = salesforceContractID;
    this.status = status;
    this.isSingleContract = isSingleContract;
  }

  @Mutation
  public clearContract() {
    this.products = [];
    this.contractID = '';
    this.salesforceContractID = '';
    this.status = '';
    this.isSingleContract = false;
  }
}

export default Contract;
