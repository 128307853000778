/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  VuexModule,
  Module,
  Mutation,
} from 'vuex-module-decorators';

interface IOptionAddCache {
  key: string;
  data: any;
}

@Module({ namespaced: true, name: 'contract' })
class RequestCache extends VuexModule {
  public cache: any = {};

  @Mutation
  public add(options: IOptionAddCache) {
    const { key, data } = options;
    this.cache[key] = data;
  }

  @Mutation
  public clear() {
    this.cache = {};
  }

  @Mutation
  public remove(key: string) {
    delete this.cache[key];
  }
}

export default RequestCache;
