
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { },
})

export default class CheckBox extends Vue {
  @Prop({
    required: false,
    type: String,
  }) readonly labelCheckBox!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly idCheckBox!: string;

  @Prop({
    required: false,
    type: String,
  }) readonly nameCheckBox!: string;

  @Prop({
    required: false,
    type: Boolean,
  }) readonly isDisabled!: boolean;

  @Prop({
    required: false,
    type: Boolean,
  }) readonly checked!: boolean;

  @Prop() clickHandler!: Function;

  handleClick(): void {
    this.clickHandler();
  }
}
