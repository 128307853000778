
import { Component, Prop, Vue } from 'vue-property-decorator';
// @ts-expect-error:
import auth from '@platform/authentication';
import { IUser } from '@/types/user';
import ModalActivate2FA from './ModalActivate2FA.vue';

export interface IPasswordHistoryError {
  code: string;
  message: string;
  httpStatusCode: number;
}

@Component({
  components: {
    ModalActivate2FA,
  },
})
export default class TabSecurity extends Vue {
  @Prop({
    required: true,
    default: 'my-account',
    type: String,
  }) readonly activeTab!: string;

  @Prop({
    required: true,
    default: () => ({}),
    type: Object,
  }) readonly user!: IUser;

  public loading = false;

  public async onActivate2FA() {
    await auth.logout();
  }

  public isZenviaEmail(email:string) {
    const zenviaEmailRegex = /^[a-zA-Z0-9._%+-]+@zenvia\.com$/;
    return zenviaEmailRegex.test(email);
  }
}
