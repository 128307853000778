import Vue from 'vue';
import { PluginObject } from 'vue/types/umd';

import loading from './loading';

const Plugin: PluginObject<unknown> = {
  install: (intance: typeof Vue): void => {
    Object.defineProperties(intance.prototype, {
      $loading: {
        get() {
          return loading;
        },
      },
    });
  },
};

export default Plugin;
