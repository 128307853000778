
import { formatCurrency, formatNumbersByLang } from '@/utils/numberFormats';
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

interface ChannelPlanProps {
  price: number;
  sms: number;
  whatsapp: number;
  whatsappUser: number;
  email: number;
  smsUnitPrice: number;
  whatsappBusinessUnitPrice: number;
  whatsappUserUnitPrice: number;
  emailUnitPrice: number;
}

@Component
export default class ChannelPlanDetails extends Vue {
  @Prop({
    required: true,
    default: () => ({}),
    type: Object,
  }) private channelPlanProps!: ChannelPlanProps

  @Prop({
    required: true,
    default: () => '',
    type: String,
  }) private currency!: string;

  formatCurrency = formatCurrency;

  formatNumbersByLang = formatNumbersByLang;

  private isAccordionActive = false;

  toggleAccordion() {
    this.isAccordionActive = !this.isAccordionActive;
  }
}
