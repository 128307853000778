export enum DocumentType {
  bill = 'bill',
  detailedConsumption = 'detailedConsumption',
  invoice = 'invoice',
  invoiceSVA = 'invoiceSVA',
  xml = 'xml',
}

export enum DocumentStatus {
  paid = 'QUITADO',
  opened = 'EM_ABERTO',
}

export enum StatusType {
  QUITADO = 'success',
  BAIXADO = 'warning',
  EM_ABERTO = 'warning',
  PARCIAL = 'warning',
  PERDA = 'warning',
  REEMITIDO = 'info',
  CANCELADO = 'danger',
}

export enum StatusLabel {
  QUITADO = 'PAID',
  BAIXADO = 'OPENED',
  EM_ABERTO = 'OPENED',
  PARCIAL = 'OPENED',
  PERDA = 'OPENED',
  REEMITIDO = 'RESENT',
  CANCELADO = 'CANCELED',
}

export interface IFiscalDocument {
  url: string;
  type: DocumentType;
  status?: DocumentStatus;
  value?: string;
}

export interface IFiscalDocumentSAP {
  url: string;
  type: DocumentType;
}
