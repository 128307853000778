
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { formatCurrency } from '@/utils/numberFormats';
import PlanCapabilities from './PlanCapabilities.vue';

interface PlansAndChannelsData {
  id: number;
  price: number;
  sms?: number;
  whatsapp?: number;
  email?: number;
  name?: string;
  users?: number;
  interactionz?: number;
  priceForExceededUsers?: number
  priceForExceededInteratcionz?: number;
}

interface CardData {
  title: string;
  type: 'software' | 'channel';
  currency: string;
  data: PlansAndChannelsData[];
}

@Component({
  components: {
    PlanCapabilities,
  },
})
export default class SelectPlanCard extends Vue {
  @Prop({
    required: true,
    default: () => ({}),
    type: Object,
  }) private cardData!: CardData

  @Prop({
    required: true,
    default: () => false,
    type: Boolean,
  }) private isActive!: boolean

  @Prop({
    required: true,
    default: () => ({}),
    type: Object,
  }) private defaultSelected!: PlansAndChannelsData;

  formatCurrency = formatCurrency;

  valueChanged(item: PlansAndChannelsData) {
    this.$emit('onRadioButtonClicked', this.cardData.type, item);
  }

  selectedItem: PlansAndChannelsData = {} as PlansAndChannelsData;

  mounted() {
    this.selectedItem = this.defaultSelected;
  }

  @Watch('defaultSelected', { deep: true })
  setSelectedItem() {
    this.selectedItem = this.defaultSelected;
  }
}
