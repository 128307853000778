import Vue from 'vue';
import Vuex from 'vuex';

import RequestCache from '@/store/modules/request-cache';
import Contract from '@/store/modules/contract';
import ZccContract from '@/store/modules/zccContract';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    contract: Contract,
    requestCache: RequestCache,
    zccContract: ZccContract,
  },
});
