
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PageHeader extends Vue {
  @Prop({
    required: true,
    default: 'Title',
    type: String,
  }) readonly title!: string;

  @Prop({
    required: false,
    type: String,
  }) readonly subTitle!: string;
}

