
import {
  Component,
  Prop,
  Watch,
  Vue,
} from 'vue-property-decorator';

import Select, { ISelectOptions } from '@/components/form/Select.vue';

interface LanguageOptionsFormat {
  label: string;
  value: string;
}

@Component({
  components: { Select },
})
export default class SelectLanguage extends Vue {
  @Prop({
    required: true,
    type: String,
  }) readonly id!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly value!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly label!: string;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly disabled!: boolean;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly error!: boolean;

  @Prop({
    required: false,
    type: String,
    default: '',
  }) readonly errorMessage!: string;

  @Prop({
    required: false,
    type: String,
    default: '',
  }) readonly helper!: string;

  public selected = '';

  public loading = false;

  private languageOptionsData: LanguageOptionsFormat[] = [];

  public get options(): ISelectOptions[] {
    return this.languageOptionsData;
  }

  @Watch('selected')
  private changeSelected(newValue: string) {
    this.$emit('input', newValue);
  }

  created() {
    this.buildLanguageOptions();
  }

  mounted() {
    this.selected = this.value;
  }

  private buildLanguageOptions() {
    this.languageOptionsData = [
      {
        label: 'Português',
        value: 'pt-BR',
      },
      {
        label: 'English',
        value: 'en-US',
      },
      {
        label: 'Español',
        value: 'es',
      },
    ];
  }
}
