
import Component, { mixins } from 'vue-class-component';

import SessionMixin from '@/mixins/session';

import AsyncProvider from '@/components/AsyncProvider.vue';
import Hint from '@/components/Hint.vue';
import Skeleton from '@/components/Skeleton.vue';

import TabAccount from './components/Tab.vue';
import TabAddress from './components/TabAddress.vue';
import TabChangePassword from './components/TabChangePassword.vue';
import TabMyAccount from './components/TabMyAccount.vue';
import TabSecurity from './components/TabSecurity.vue';

@Component({
  components: {
    Skeleton,
    TabAccount,
    TabAddress,
    TabMyAccount,
    TabSecurity,
    TabChangePassword,
    AsyncProvider,
    Hint,
  },
})
export default class AccountView extends mixins(SessionMixin) {
  public maestroContextType = (window as any).maestroContextType;

  public get url() {
    return `${process.env.VUE_APP_USM_URL}/users/${this.getIdUser}`;
  }

  private get getIdUser() {
    return this.mxSession_user.id;
  }

  public get headerItems() {
    const breadcrumItens = [];

    if (this.maestroContextType === 'APP') {
      breadcrumItens.push({
        label: `${this.$t('INVOICES_RECEIPTS.BREADCRUMBS.HOME')}`,
        href: '/welcome',
      });
    }

    breadcrumItens.push(...[
      {
        label: `${this.$t('MENU_LEFT.CUSTOMER_AREA')}`,
      },
      {
        label: `${this.$t('MENU_LEFT.MY_ACCOUNT')}`,
        isActive: true,
      },
    ]);

    return [
      {
        title: `${this.$t('ACCOUNT.TITLE')}`,
        subtitle: `${this.$t('ACCOUNT.SUB_TITLE')}`,
        breadcrumItens,
        buttons: [],
      },
    ];
  }
}

