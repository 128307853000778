import { NavigationGuardNext, Route } from 'vue-router';
import { sessionMixin } from '../../mixins/session';

// eslint-disable-next-line import/prefer-default-export
export const beforeEach = (to: Route, from: Route, next: NavigationGuardNext) => {
  if (!to.meta?.profiles) {
    return next();
  }

  const authorized = to.meta?.profiles.find((el: string) => el === sessionMixin.mxSession_profile.id);
  if (authorized) {
    return next();
  }
  return next(false);
};
