
import {
  Component,
  Prop,
  Watch,
  Vue,
} from 'vue-property-decorator';
import { AxiosRequestConfig } from 'axios';
import Select, { ISelectOptions } from '@/components/form/Select.vue';
import { ICity } from '@/types/city';

@Component({
  components: { Select },
})
export default class SelectCity extends Vue {
  @Prop({
    required: true,
    type: String,
  }) readonly id!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly value!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly stateCode!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly label!: string;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly disabled!: boolean;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly error!: boolean;

  @Prop({
    required: false,
    type: String,
    default: '',
  }) readonly errorMessage!: string;

  @Prop({
    required: false,
    type: String,
    default: '',
  }) readonly helper!: string;

  public selected = '';

  public loading = false;

  public options: ISelectOptions[] = [];

  @Watch('value')
  private changeValue(newValue: string) {
    this.selected = newValue;
  }

  @Watch('selected')
  private changeSelected(newValue: string) {
    this.$emit('input', newValue);
  }

  @Watch('stateCode')
  private changeStateCode(newValue: string) {
    this.buildCityOptions(newValue);
    this.selected = '';
  }

  mounted() {
    // Force component update
    this.selected = this.value;
  }

  created() {
    this.buildCityOptions(this.stateCode);
  }

  private async buildCityOptions(stateCode: string): Promise<void> {
    const cities = await this.getCityList(stateCode);

    this.options = cities
      .map((el: ICity): ISelectOptions => {
        const { name } = el;
        return {
          label: name,
          value: name,
        };
      });
  }

  private async getCityList(stateCode: string): Promise<ICity[]> {
    this.loading = true;
    try {
      const url = `${process.env.VUE_APP_CUSTOMER_PERSONAL_DATA_URL}/v1/resources/cities`;

      const options: AxiosRequestConfig = {
        params: {
          stateInitials: stateCode,
        },
      };

      const { data } = await this.$http.get<ICity[]>(url, options);

      return data;
    } catch {
      this.selected = '';

      return [];
    } finally {
      this.loading = false;
    }
  }
}
