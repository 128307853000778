
import { Watch } from 'vue-property-decorator';
import Component, { mixins } from 'vue-class-component';

import { namespace } from 'vuex-class';

import ZccContractStore from '@/store/modules/zccContract';

import SessionMixin from '@/mixins/session';

import AsyncProvider from '@/components/AsyncProvider.vue';
import Skeleton from '@/components/Skeleton.vue';
import Hint from '@/components/Hint.vue';
import { IContract } from '@/types/contract';

import CardContractZcc from './components/CardContractZcc.vue';
import { IContractZcc } from '@/types/contract-zcc';

const zccContract = namespace('zccContract');

@Component({
  components: {
    CardContractZcc,
    AsyncProvider,
    Skeleton,
    Hint,
  },
})

export default class InvoicesZccView extends mixins(SessionMixin) {
  private once = false;

  private touched = false;

  public showHintForNewCustomerArea = false;

  public returnurl = `${process.env.VUE_APP_PAYMENT_HISTORY_URL}/v1/zcc-subscription`;

  public loading = true;

  public maestroContextType = (window as any).maestroContextType;

  public statuses = ['Activated', 'Draft', 'In Approval Process', 'Cancelamento Agendado', 'Cancelado', 'Bloqueado', 'Rescindido'];

  public searchTerm = {
    stringFilter: '',
    statusFilter: this.$t('INVOICES_ZCC.DROPDOWN_DEFAULT'),
  };

  @Watch('searchTerm', { deep: true })
  private changeFilterValue(newValue: any) {
    this.touched = true;
    this.searchTerm = newValue;
  }

  @zccContract.Mutation
  public setZccContract!: (contract: ZccContractStore) => void;

  get filteredData() {
    if (!this.touched) {
      // @ts-ignore
      return this.$refs.contractsProvider.data;
    }
    // @ts-ignore
    return this.$refs.contractsProvider.data?.filter((item) => item.status === this.searchTerm.statusFilter && (item.contractID.toLowerCase().includes(this.searchTerm.stringFilter) || item.products.some((product) => product.toLowerCase().includes(this.searchTerm.stringFilter))));
  }

  public clearFilter() {
    this.searchTerm.stringFilter = '';
  }

  async created() {
    this.showHintForNewCustomerArea = await this.$canIViewFeature({ name: 'hint-for-new-customer-area' });

    await (this.$refs.contractsProvider as AsyncProvider).requestData();
    this.loading = false;
  }

  public get url(): string {
    return this.returnurl;
  }

  public get params() {
    return {
      organizationId: this.mxSession_organization.id,
      email: this.mxSession_user.email,
    };
  }

  public get headerItems() {
    return [
      {
        title: `${this.$t('INVOICE.SUITE_TITLE')}`,
        subtitle: `${this.$t('INVOICES_ZCC.HEADER.SUBTITLE')}`,
        link: `${this.$t('INVOICES_ZCC.HEADER.HELP_CENTER_LINK')}`,
        labellink: `${this.$t('INVOICES_ZCC.HEADER.HELP_CENTER')}`,
        breadcrumItens: [
          {
            label: `${this.$t('INVOICES_RECEIPTS.BREADCRUMBS.CONFIGS')}`,
          },
          {
            label: `${this.$t('INVOICE.SUITE_TITLE')}`,
            isActive: true,
          },
        ],
        buttons: [],
      },
    ];
  }

  public goToNewCustomerArea() {
    window.open(process.env.VUE_APP_NEW_CUSTOMER_AREA_URL);
  }

  // eslint-disable-next-line
  public orderByStatus(data: IContract[]) {
    if (!this.once) {
      return data.sort((contract1: IContract, contract2: IContract) => {
        if (contract1.status === 'Activated') { return -2; }
        if (contract2.status === 'Activated') { return 2; }
        if (contract1.status < contract2.status) { return -1; }
        if (contract1.status > contract2.status) { return 1; }
        return 0;
      });
    }

    return data;
  }

  afterRequest(data: IContractZcc) {
    this.setZccContract({
      planId: data.plan.id,
      currency: data.currency,
      zenviaSubscriptionCode: data.zenviaSubscriptionCode,
      country: data.country,
      status: data.status,
      salesforceContractId: data.salesforceContractId,
    } as ZccContractStore);
  }
}

