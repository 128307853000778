
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface IitensTabs {
  label: string;
  active: boolean;
  dataTrack: string;
  clickTab?: () => void;
}

@Component
export default class itIitensTabs extends Vue {
  @Prop({
    required: true,
    default: () => [],
  }) paths!: IitensTabs[];

  get itens(): IitensTabs[] {
    return this.paths || [];
  }
}

