
import { Component, Vue } from 'vue-property-decorator';

import { Events } from '@/plugins';

import { INotification } from '@/types/notification';

import Notification from './Notification.vue';

@Component({
  components: { Notification },
})
export default class Toasts extends Vue {
  private notifications: INotification[] = [];

  created() {
    this.$eventBus.$on(Events.Alert, this.show);
  }

  destroy() {
    this.$eventBus.$off(Events.Alert, this.show);
  }

  private show(notification: INotification): void {
    this.notifications.push(notification);
  }
}
