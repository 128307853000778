/* eslint-disable @typescript-eslint/no-explicit-any */

import Vue from 'vue';
import { PluginObject } from 'vue/types/umd';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import initInterceptors from './interceptors';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config: AxiosRequestConfig = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const $axios: AxiosInstance = axios.create(config);

initInterceptors($axios);

const Plugin: PluginObject<unknown> = {
  install: (intance: typeof Vue): void => {
    Object.defineProperties(intance.prototype, {
      $http: {
        get() {
          return $axios;
        },
      },
    });
  },
};

export default Plugin;

export { $axios as axios };
