
import {
  Component,
  Vue, Prop,
} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import Checkbox from '@/components/form/Checkbox.vue';
// @ts-expect-error:
import FeedbackService from '@platform/feedback-service';
import { IUser } from '@/types/user';

@Component({
  components: {
    Modal,
    Checkbox,
  },
})
export default class ModalActivate2FA extends Vue {
  @Prop({
    required: true,
    default: () => ({}),
    type: Object,
  }) readonly user!: IUser;

  public agreedTerm = false;

  public loading = false;

  private get url() {
    return `${process.env.VUE_APP_USM_URL}/users/${this.user.id}/require-mfa`;
  }

  private get modalActive2FA(): Modal {
    return (this.$refs.active2FA as Modal);
  }

  public async activate2FA(): Promise<void> {
    this.loading = true;
    try {
      await this.$http.patch(this.url);

      this.$emit('activate2FA');

      FeedbackService.toastr.show('success', this.$t('ACCOUNT.SECURITY.ACTIONS.TOAST_SUCCESS'));
      this.close();
    } catch (error) {
      FeedbackService.toastr.show('danger', this.$t('ACCOUNT.SECURITY.ACTIONS.TOAST_ERROR'));
    } finally {
      this.loading = false;
    }
  }

  public close() {
    this.modalActive2FA.close();
  }

  public open() {
    this.modalActive2FA.open();
  }

  public toggle() {
    this.modalActive2FA.toggle();
  }
}
