
import {
  Component,
  Prop,
  Watch,
  Vue,
} from 'vue-property-decorator';

import Select, { ISelectOptions } from '@/components/form/Select.vue';

@Component({
  components: { Select },
})
export default class SelectStates extends Vue {
  @Prop({
    required: true,
    type: String,
  }) readonly id!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly value!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly label!: string;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly disabled!: boolean;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly error!: boolean;

  @Prop({
    required: false,
    type: String,
    default: '',
  }) readonly errorMessage!: string;

  @Prop({
    required: false,
    type: String,
    default: '',
  }) readonly helper!: string;

  public selected = '';

  public options: ISelectOptions[] = [
    {
      label: 'Acre',
      value: 'AC',
    },
    {
      label: 'Alagoas',
      value: 'AL',
    },
    {
      label: 'Amapá',
      value: 'AP',
    },
    {
      label: 'Amazonas',
      value: 'AM',
    },
    {
      label: 'Bahia',
      value: 'BA',
    },
    {
      label: 'Ceará',
      value: 'CE',
    },
    {
      label: 'Distrito Federal',
      value: 'DF',
    },
    {
      label: 'Espírito Santo',
      value: 'ES',
    },
    {
      label: 'Goiás',
      value: 'GO',
    },
    {
      label: 'Maranhão',
      value: 'MA',
    },
    {
      label: 'Mato Grosso',
      value: 'MT',
    },
    {
      label: 'Mato Grosso do Sul',
      value: 'MS',
    },
    {
      label: 'Minas Gerais',
      value: 'MG',
    },
    {
      label: 'Pará',
      value: 'PA',
    },
    {
      label: 'Paraíba',
      value: 'PB',
    },
    {
      label: 'Paraná',
      value: 'PR',
    },
    {
      label: 'Pernambuco',
      value: 'PE',
    },
    {
      label: 'Piauí',
      value: 'PI',
    },
    {
      label: 'Rio de Janeiro',
      value: 'RJ',
    },
    {
      label: 'Rio Grande do Norte',
      value: 'RN',
    },
    {
      label: 'Rio Grande do Sul',
      value: 'RS',
    },
    {
      label: 'Rondônia',
      value: 'RO',
    },
    {
      label: 'Roraima',
      value: 'RR',
    },
    {
      label: 'Santa Catarina',
      value: 'SC',
    },
    {
      label: 'São Paulo',
      value: 'SP',
    },
    {
      label: 'Sergipe',
      value: 'SE',
    },
    {
      label: 'Tocantins',
      value: 'TO',
    },
  ]

  @Watch('value')
  private async changeValue(newValue: string) {
    this.selected = newValue;
  }

  @Watch('selected')
  private changeSelected(newValue: string) {
    this.$emit('input', newValue);
  }

  mounted() {
    // Force component update
    this.selected = this.value;
  }
}
