(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@platform/authentication"), require("@platform/customer-data-service"), require("@platform/feature-toggle"), require("@platform/feedback-service"));
	else if(typeof define === 'function' && define.amd)
		define(["@platform/authentication", "@platform/customer-data-service", "@platform/feature-toggle", "@platform/feedback-service"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@platform/authentication"), require("@platform/customer-data-service"), require("@platform/feature-toggle"), require("@platform/feedback-service")) : factory(root["@platform/authentication"], root["@platform/customer-data-service"], root["@platform/feature-toggle"], root["@platform/feedback-service"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__51b8__, __WEBPACK_EXTERNAL_MODULE__5e0a__, __WEBPACK_EXTERNAL_MODULE__8a06__, __WEBPACK_EXTERNAL_MODULE_d180__) {
return 