
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

export interface ISelectOptions {
  label: string;
  value?: unknown;
}

@Component({
  components: {},
})
export default class Select extends Vue {
  @Prop({
    required: true,
    type: String,
  }) readonly id!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly value!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly label!: string;

  @Prop({
    required: true,
    type: Array,
  }) readonly options!: ISelectOptions[];

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly loading!: boolean;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly disabled!: boolean;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly error!: boolean;

  @Prop({
    required: false,
    type: String,
    default: '',
  }) readonly errorMessage!: string;

  @Prop({
    required: false,
    type: String,
    default: '',
  }) readonly helper!: string;

  public selected = '';

  public get helperText(): string {
    return (this.error && this.errorMessage) || this.helper;
  }

  @Watch('value')
  private changeValue(newValue: string) {
    this.selected = newValue;
  }

  @Watch('selected')
  private changeSelected(newValue: string) {
    this.$emit('input', newValue);
  }
}
