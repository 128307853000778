
import { formatCurrency, formatNumbersByLang } from '@/utils/numberFormats';
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

interface SoftwarePlanCapabilities {
  users: number
  interactionz: number
  priceForExceededUsers: number
  priceForExceededInteratcionz: number
}

interface ChannelPlanCapabilities {
  sms: number;
  whatsApp: number;
  email: number;
}

interface CapabilitiesProps {
  currency: string;
  softwarePlan?: SoftwarePlanCapabilities;
  channelPlan?: ChannelPlanCapabilities;
  size: 'small' | 'caption'
}

@Component
export default class PlanCapabilities extends Vue {
  @Prop({
    required: true,
    default: () => ({}),
    type: Object,
  }) private capabilitiesProps!: CapabilitiesProps

  formatCurrency = formatCurrency;

  formatNumbersByLang = formatNumbersByLang;
}
