
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { },
})
export default class Notification extends Vue {
  @Prop({
    required: false,
    default: '',
    type: String,
  }) readonly title!: string;

  @Prop({
    required: true,
    default: '',
    type: String,
  }) readonly message!: string;

  @Prop({
    required: true,
    type: String,
    validator: (value: string) => {
      const options = new Set(['info', 'success', 'warning', 'danger']);
      return options.has(value);
    },
  }) readonly type!: string;

  @Prop({
    required: false,
    type: Number,
    default: 0,
  }) readonly duration!: number;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly animated!: number;

  private visible = false;

  mounted() {
    if (this.duration && this.duration > 0) {
      this.scheduleClose();
    }
    this.visible = true;
  }

  public close() {
    this.visible = false;

    setTimeout(() => {
      if (this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
    }, 2000);
  }

  private scheduleClose(): void {
    setTimeout(() => {
      this.close();
    }, this.duration);
  }
}

