import Vue from 'vue';
import { PluginObject } from 'vue/types/umd';

// @ts-expect-error:
import { canIView } from '@platform/feature-toggle';

interface IToggleOptions {
  skipCache: boolean;
}

export interface ICanViewFeatureOptions {
  name: string;
  version?: string;
  options?: IToggleOptions;
}

export const canIViewFeature = async (options: ICanViewFeatureOptions): Promise<boolean> => {
  try {
    const { isAllowed } = await canIView(options);
    return isAllowed;
  } catch (error) {
    return false;
  }
};

const Plugin: PluginObject<unknown> = {
  install: (intance: typeof Vue): void => {
    Object.defineProperties(intance.prototype, {
      $canIViewFeature: {
        get() {
          return canIViewFeature;
        },
      },
    });
  },
};

export default Plugin;
