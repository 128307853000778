export const AVAILABLE_PLANS = {
  BRL: [
    {
      id: 1,
      name: 'Starter',
      price: 0,
      users: 1,
      interactionz: 100,
      priceForExceededUsers: 250,
      priceForExceededInteratcionz: 2.50,
      activationPrice: 649,
    },
    {
      id: 2,
      name: 'Specialist',
      price: 600,
      users: 10,
      interactionz: 500,
      priceForExceededUsers: 150,
      priceForExceededInteratcionz: 1.32,
      activationPrice: 649,
    },
    {
      id: 3,
      name: 'Expert',
      price: 1800,
      users: 30,
      interactionz: 2000,
      priceForExceededUsers: 100,
      priceForExceededInteratcionz: 0.99,
      activationPrice: 1999,
    },
    {
      id: 4,
      name: 'Professional',
      price: 3900,
      users: 50,
      interactionz: 5000,
      priceForExceededUsers: 100,
      priceForExceededInteratcionz: 0.86,
      activationPrice: 3999,
    },

  ],
  USD: [
    {
      id: 1,
      name: 'Starter',
      price: 0,
      users: 1,
      interactionz: 100,
      priceForExceededUsers: 53,
      priceForExceededInteratcionz: 1,
      activationPrice: 137,
    },
    {
      id: 2,
      name: 'Specialist',
      price: 130,
      users: 10,
      interactionz: 500,
      priceForExceededUsers: 32,
      priceForExceededInteratcionz: 0.28,
      activationPrice: 137,
    },
    {
      id: 3,
      name: 'Expert',
      price: 390,
      users: 30,
      interactionz: 2000,
      priceForExceededUsers: 22,
      priceForExceededInteratcionz: 0.21,
      activationPrice: 421,
    },
    {
      id: 4,
      name: 'Professional',
      price: 845,
      users: 50,
      interactionz: 5000,
      priceForExceededUsers: 22,
      priceForExceededInteratcionz: 0.19,
      activationPrice: 842,
    },
  ],
  MXN: [
    {
      id: 1,
      name: 'Starter',
      price: 0,
      users: 1,
      interactionz: 100,
      priceForExceededUsers: 917,
      priceForExceededInteratcionz: 18,
      activationPrice: 2369,
    },
    {
      id: 2,
      name: 'Specialist',
      price: 2200,
      users: 10,
      interactionz: 500,
      priceForExceededUsers: 554,
      priceForExceededInteratcionz: 5,
      activationPrice: 2369,
    },
    {
      id: 3,
      name: 'Expert',
      price: 6600,
      users: 30,
      interactionz: 2000,
      priceForExceededUsers: 381,
      priceForExceededInteratcionz: 4,
      activationPrice: 7279,
    },
    {
      id: 4,
      name: 'Professional',
      price: 14300,
      users: 50,
      interactionz: 5000,
      priceForExceededUsers: 381,
      priceForExceededInteratcionz: 4,
      activationPrice: 14557,
    },
  ],
};

export const AVAILABLE_CHANNELS = {
  BRL: [
    {
      id: 1,
      price: 100,
      sms: 1000,
      whatsapp: 182,
      whatsappUser: 334,
      email: 25000,
      smsUnitPrice: 0.100,
      whatsappBusinessUnitPrice: 0.550,
      whatsappUserUnitPrice: 0.300,
      emailUnitPrice: 0.0040,
    },
    {
      id: 2,
      price: 250,
      sms: 2942,
      whatsapp: 472,
      whatsappUser: 893,
      email: 71429,
      smsUnitPrice: 0.085,
      whatsappBusinessUnitPrice: 0.530,
      whatsappUserUnitPrice: 0.280,
      emailUnitPrice: 0.0035,
    },
    {
      id: 3,
      price: 500,
      sms: 6250,
      whatsapp: 981,
      whatsappUser: 1924,
      email: 166667,
      smsUnitPrice: 0.080,
      whatsappBusinessUnitPrice: 0.510,
      whatsappUserUnitPrice: 0.260,
      emailUnitPrice: 0.0030,
    },
    {
      id: 4,
      price: 1000,
      sms: 13334,
      whatsapp: 2041,
      whatsappUser: 4167,
      email: 400000,
      smsUnitPrice: 0.075,
      whatsappBusinessUnitPrice: 0.490,
      whatsappUserUnitPrice: 0.240,
      emailUnitPrice: 0.0025,
    },
    {
      id: 5,
      price: 2000,
      sms: 28572,
      whatsapp: 4256,
      whatsappUser: 9091,
      email: 1000000,
      smsUnitPrice: 0.070,
      whatsappBusinessUnitPrice: 0.470,
      whatsappUserUnitPrice: 0.220,
      emailUnitPrice: 0.0020,
    },
  ],
  USD: [
    {
      id: 1,
      price: 20,
      sms: 1088,
      whatsapp: 431,
      whatsappUser: 1079,
      email: 23750,
      smsUnitPrice: 0.0184,
      whatsappBusinessUnitPrice: 0.0463,
      whatsappUserUnitPrice: 0.0185,
      emailUnitPrice: 0.00084,
    },
    {
      id: 2,
      price: 50,
      sms: 3202,
      whatsapp: 1120,
      whatsappUser: 2891,
      email: 67857,
      smsUnitPrice: 0.0156,
      whatsappBusinessUnitPrice: 0.0446,
      whatsappUserUnitPrice: 0.0173,
      emailUnitPrice: 0.00074,
    },
    {
      id: 3,
      price: 100,
      sms: 6804,
      whatsapp: 2328,
      whatsappUser: 6228,
      email: 158333,
      smsUnitPrice: 0.0147,
      whatsappBusinessUnitPrice: 0.0429,
      whatsappUserUnitPrice: 0.0161,
      emailUnitPrice: 0.00063,
    },
    {
      id: 4,
      price: 200,
      sms: 14517,
      whatsapp: 4842,
      whatsappUser: 13494,
      email: 380000,
      smsUnitPrice: 0.0138,
      whatsappBusinessUnitPrice: 0.0413,
      whatsappUserUnitPrice: 0.0148,
      emailUnitPrice: 0.00053,
    },
    {
      id: 5,
      price: 400,
      sms: 31108,
      whatsapp: 10106,
      whatsappUser: 29442,
      email: 950000,
      smsUnitPrice: 0.0129,
      whatsappBusinessUnitPrice: 0.0396,
      whatsappUserUnitPrice: 0.0136,
      emailUnitPrice: 0.00042,
    },
  ],
  MXN: [
    {
      id: 2,
      price: 400,
      sms: 137,
      whatsapp: 297,
      whatsappUser: 1085,
      email: 27475,
      smsUnitPrice: 2.9071,
      whatsappBusinessUnitPrice: 1.3465,
      whatsappUserUnitPrice: 0.3685,
      emailUnitPrice: 0.0146,
    },
    {
      id: 2,
      price: 1000,
      sms: 404,
      whatsapp: 770,
      whatsappUser: 2907,
      email: 78500,
      smsUnitPrice: 2.470,
      whatsappBusinessUnitPrice: 1.2976,
      whatsappUserUnitPrice: 0.3439,
      emailUnitPrice: 0.0127,
    },
    {
      id: 3,
      price: 2000,
      sms: 859,
      whatsapp: 1601,
      whatsappUser: 6262,
      email: 183167,
      smsUnitPrice: 2.3257,
      whatsappBusinessUnitPrice: 1.2486,
      whatsappUserUnitPrice: 0.3194,
      emailUnitPrice: 0.0109,
    },
    {
      id: 4,
      price: 4000,
      sms: 1834,
      whatsapp: 3334,
      whatsappUser: 13568,
      email: 439601,
      smsUnitPrice: 2.1803,
      whatsappBusinessUnitPrice: 1.1996,
      whatsappUserUnitPrice: 0.2948,
      emailUnitPrice: 0.0091,
    },
    {
      id: 5,
      price: 8000,
      sms: 3931,
      whatsapp: 6952,
      whatsappUser: 29604,
      email: 1099004,
      smsUnitPrice: 2.0350,
      whatsappBusinessUnitPrice: 1.1507,
      whatsappUserUnitPrice: 0.2702,
      emailUnitPrice: 0.0073,
    },
  ],
};

export const PLANS_CHANNELS_COMBINATIONS = [
  {
    planId: 'de89132f-7007-4dcd-905a-273df60c23a7',
    softwarePlanId: 1,
    channelPlanId: 1,
  },
  {
    planId: '245beaab-618d-4a42-adee-85a958b6bbfe',
    softwarePlanId: 1,
    channelPlanId: 2,
  },
  {
    planId: '5407c38d-e944-47bc-9c25-dc5ac943c0df',
    softwarePlanId: 1,
    channelPlanId: 3,
  },
  {
    planId: '3afae1e0-696b-4ae5-bc1d-f658fd664b2e',
    softwarePlanId: 1,
    channelPlanId: 4,
  },
  {
    planId: 'b106d9a3-3184-47e9-be76-be0a8a915431',
    softwarePlanId: 1,
    channelPlanId: 5,
  },
  {
    planId: 'cd57ed9f-547f-4f5f-ad81-41247b9ed877',
    softwarePlanId: 2,
    channelPlanId: 1,
  },
  {
    planId: '57da4a9c-3ec9-414b-98e2-839d900bfe24',
    softwarePlanId: 2,
    channelPlanId: 2,
  },
  {
    planId: '3910decc-7f21-4526-941b-0ac792a9cbc7',
    softwarePlanId: 2,
    channelPlanId: 3,
  },
  {
    planId: 'f3bdd29d-6959-4b11-b6ba-447aba42cfaa',
    softwarePlanId: 2,
    channelPlanId: 4,
  },
  {
    planId: '894a2ec5-b900-47fb-928c-dc8504aef984',
    softwarePlanId: 2,
    channelPlanId: 5,
  },
  {
    planId: '4b82dd66-8c91-41d6-b9dc-5de3d7ed220c',
    softwarePlanId: 4,
    channelPlanId: 1,
  },
  {
    planId: 'f8f1ae5d-d2c7-4c27-9897-b8c5de805da9',
    softwarePlanId: 3,
    channelPlanId: 2,
  },
  {
    planId: 'de1e0762-d677-4b12-b72c-8c6f87305fc3',
    softwarePlanId: 3,
    channelPlanId: 3,
  },
  {
    planId: '97db58bd-482d-484e-bfde-069ea00ea0e2',
    softwarePlanId: 3,
    channelPlanId: 4,
  },
  {
    planId: 'eef47dc3-6d8c-4924-ad08-ac550509c2a3',
    softwarePlanId: 3,
    channelPlanId: 5,
  },
  {
    planId: 'ec88455a-e6ce-4c64-9db6-3e3747a30508',
    softwarePlanId: 4,
    channelPlanId: 1,
  },
  {
    planId: '378e0ae9-1788-49ab-9f98-e8553038f0d0',
    softwarePlanId: 4,
    channelPlanId: 2,
  },
  {
    planId: '37164ec2-aa1e-4085-89b6-5957df4a24b9',
    softwarePlanId: 4,
    channelPlanId: 3,
  },
  {
    planId: '9f42c138-7298-435d-875e-3376f65973ce',
    softwarePlanId: 4,
    channelPlanId: 4,
  },
  {
    planId: '1af8f778-f696-4c71-b9e1-bc46545d775b',
    softwarePlanId: 4,
    channelPlanId: 5,
  },
];
