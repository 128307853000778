
import Component, { mixins } from 'vue-class-component';

import SessionMixin from '@/mixins/session';

import PageHeader from '@/components/PageHeader.vue';
import AsyncProvider from '@/components/AsyncProvider.vue';
import Skeleton from '@/components/Skeleton.vue';
import Hint from '@/components/Hint.vue';
import Breadcrumb, { IBreadcrumb } from '@/components/Breadcrumb.vue';

import TabAccount from './components/Tab.vue';
import TabMyAccount from './components/TabMyAccount.vue';
import TabChangePassword from './components/TabChangePassword.vue';
import TabAddress from './components/TabAddress.vue';
import TabSecurity from './components/TabSecurity.vue';

@Component({
  components: {
    PageHeader,
    Skeleton,
    TabAccount,
    TabAddress,
    TabMyAccount,
    TabSecurity,
    TabChangePassword,
    AsyncProvider,
    Hint,
    Breadcrumb,
  },
})
export default class AccountView extends mixins(SessionMixin) {
  public get url() {
    return `${process.env.VUE_APP_USM_URL}/users/${this.getIdUser}`;
  }

  private get getIdUser() {
    return this.mxSession_user.id;
  }

  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        labelLink: `${this.$t('INVOICES_RECEIPTS.BREADCRUMBS.HOME')}`,
        path: '/welcome',
      },
      {
        label: `${this.$t('INVOICES_RECEIPTS.BREADCRUMBS.CUSTOMER_AREA')}`,
        to: '/preferences/my-account',
      },
      {
        label: `${this.$t('MENU_LEFT.MY_ACCOUNT')}`,
        to: '#',
      },
    ];
  }
}

