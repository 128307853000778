
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { },
})
export default class Hint extends Vue {
  @Prop({
    required: false,
    type: String,
  }) readonly title!: string;

  @Prop({
    required: true,
    type: String,
    validator: (value: string) => {
      const options = new Set(['info', 'warning', 'danger']);
      return options.has(value);
    },
  }) readonly type!: string;
}

